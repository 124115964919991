import React, { useState, useEffect, useMemo } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router-dom";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
const TableWithPagination = ({ data, defaultItemsPerPage, fetchReoprts }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
  const [totalPages, setTotalPages] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [datadelete, setDelete] = useState();
  const [loopen, setLoOpen] = useState(false);
  const [sort, setSort] = useState({ column: null, direction: "asc" });
  const [loadingById, setLoadingById] = useState({});
  const [isOpen, setIsOpen] = useState(null);
  const [reportid, setReportid] =  useState();
  const [reporttepname, setReportTepname] =  useState();
  const togglePopup = (itemId,itemName) => {
    setIsOpen((currentOpenPopupId) => (currentOpenPopupId === itemId ? null : itemId));
    setReportid(itemId);
    setReportTepname(itemName);
  };
  const LoaderOpen = () => {
    setLoOpen(true);
  };
  const LoaderClose = () => {
    setLoOpen(false);
  };
  const openModal = (id) => {
    setIsModalOpen(true);
    setDelete(id);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to handle the download

  let navigate = useNavigate();
  const handleSetdata = (
    id,
    organization_name,
    startdate,
    enddate,
    organization_country,
    name
  ) => {
    const newdata = {
      id: id,
      organization_name: organization_name,
      start_date: startdate,
      end_date: enddate,
      country_name: organization_country,
      reportname: name,
    };

    navigate(`/report/GHGtemplate`, { state: { data: newdata } });
    window.localStorage.setItem("reportname", name);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(data.length / itemsPerPage));
  }, [data.length, itemsPerPage]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Change items per page
  const onItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page
  };

  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, data.length);

  const handleDelete = async () => {
    const stringWithQuotes = localStorage.getItem("authTokens");
    const stringWithoutQuotes = stringWithQuotes.replace(/"/g, "");
    const options = {
      headers: {
        Authorization: `Token ${stringWithoutQuotes}`,
      },
    };
    const reoprtid = datadelete;
    LoaderOpen();
    await axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/report_delete/${reoprtid}`,
        options
      )
      .then((response) => {
        if (response.status === 204) {
          console.log(response.status);
          toast.success("Report has been delete successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          LoaderClose();
          fetchReoprts();
          closeModal();
        } else {
          toast.error("Error", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          LoaderClose();
          fetchReoprts();
          closeModal();
        }
      });
  };
  const handleSort = (column) => {
    const isAsc = sort.column === column && sort.direction === "asc";
    setSort({ column, direction: isAsc ? "desc" : "asc" });
  };

  // Sorting data
  // Sorting data
  const sortedData = useMemo(() => {
    if (sort.column) {
      return [...data].sort((a, b) => {
        const columnA = a[sort.column];
        const columnB = b[sort.column];
        if (columnA < columnB) {
          return sort.direction === "asc" ? -1 : 1;
        }
        if (columnA > columnB) {
          return sort.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    } else {
      // If no column is selected for sorting, return the original data
      return data;
    }
  }, [data, sort]);

  const currentItems = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const handleDownloadpdf = async () => {
    // Set loading to true for the specific item
    setLoadingById(prevState => ({ ...prevState, [reportid]: true }));

    const accessToken = localStorage.getItem('accessToken');
  
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken.replace(/^"|"$/g, '')}`,
      }
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/report_pdf/${reportid}/?download=true`, requestOptions
      );
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${reporttepname}.pdf`); // Setting the file name dynamically
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    } finally {
      // Set loading to false for the specific item
      setLoadingById(prevState => ({ ...prevState, [reportid]: false }));
      setIsOpen(null);
    }
  };
  const handleDownloaddocx = async () => {
    const stringWithQuotes = localStorage.getItem("accessToken");
    const stringWithoutQuotes = stringWithQuotes.replace(/"/g, "");
    const options = {
      headers: {
        Authorization: `Bearer ${stringWithoutQuotes}`,
      },
    };
    setLoadingById(prevState => ({ ...prevState, [reportid]: true }));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/sustainapp/report_word_download/${reportid}/`, options

      );

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${reporttepname}.docx`); // Setting the file name dynamically
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    } finally {
      // Set loading to false for the specific item
      setLoadingById(prevState => ({ ...prevState, [reportid]: false }));
      setIsOpen(null);
    }


  };
  return (
    <>
      <div>
        <table className="min-w-max w-full table-auto ">
          <thead className="py-3 px-6 text-left text-neutral-500 text-[13px] font-extrabold leading-none">
            <tr>
              <th
                className="py-3 px-6 text-left whitespace-nowrap font-extrabold"
                onClick={() => handleSort("name")}
              >
                Name{" "}
                {sort.column === "name" ? (
                  sort.direction === "asc" ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </th>
              <th
                className="py-3 px-6 text-left whitespace-nowrap font-extrabold"
                onClick={() => handleSort("report_type")}
              >
                Report type{" "}
                {sort.column === "report_type" ? (
                  sort.direction === "asc" ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </th>
              <th
                className="py-3 px-6 text-left whitespace-nowrap font-extrabold"
                onClick={() => handleSort("start_date")}
              >
                Start date{" "}
                {sort.column === "start_date" ? (
                  sort.direction === "asc" ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </th>
              <th
                className="py-3 px-6 text-left whitespace-nowrap font-extrabold"
                onClick={() => handleSort("end_date")}
              >
                End date{" "}
                {sort.column === "end_date" ? (
                  sort.direction === "asc" ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                ) : (
                  <KeyboardArrowDownIcon />
                )}{" "}
              </th>
              <th
                className="py-3 px-6 text-left whitespace-nowrap font-extrabold"
                onClick={() => handleSort("last_report_date")}
              >
                Last report{" "}
                {sort.column === "last_report_date" ? (
                  sort.direction === "asc" ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                ) : (
                  <KeyboardArrowDownIcon />
                )}{" "}
              </th>
              <th className="py-3 px-6 text-left whitespace-nowrap font-extrabold">
                Action{" "}
              </th>
     </tr>
          </thead>


          <tbody className="text-gray-600 text-sm font-light">
            {data.length > 0 &&
              currentItems.map((item, index) => (
                <tr
                  key={index}
                  className="border-b border-gray-200 hover:bg-gray-100 text-left"
                >
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {item.name}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {item.report_type}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {item.start_date}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {item.end_date}
                  </td>

                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {item.last_report_date}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    <EditIcon
                      onClick={() =>
                        handleSetdata(
                          item.id,
                          item.organization_name,
                          item.start_date,
                          item.end_date,
                          item.organization_country,
                          item.name
                        )
                      }
                      className="cursor-pointer"
                    />
                    <DeleteIcon
                      onClick={() => openModal(item.id)}
                      className="cursor-pointer"
                    />

                {loadingById[item.id] ? (
                <CircularProgress size={24} my={2} />

              ) : (
                <>
                <DownloadIcon
                onClick={() => togglePopup(item.id,item.name)}
                // onClick={() => handleDownload(item.id, item.name)}
                  style={{ cursor: 'pointer' }}
                />

                {isOpen === item.id && (
                  <div className="absolute right-0 w-[11.3rem] bg-white shadow-xl z-10">

                       <div className="px-3 mb-1 py-2">
                        <div className="mb-2"> <h5 className="text-drak cursor-pointer" onClick={handleDownloaddocx}>Download as Docx</h5></div>
                   <div>  <h5 className="text-drak cursor-pointer" onClick={handleDownloadpdf}>Download as PDF</h5></div>

                  </div>

                </div>

                 )}
                 </>
              )}

                  </td>
                </tr>
              ))}

          </tbody>

        </table>

        <div className="justify-end items-center gap-2 flex w-[100%] mt-4">
          <div>
            <label className="text-black text-opacity-60 text-xs font-normal leading-[15px] text-[15px]">
              Rows per page:
            </label>
            <select
              value={itemsPerPage}
              onChange={onItemsPerPageChange}
              className="text-black  text-xs font-normal leading-[15px]"
            >
              {[5, 10, 15, 20].map((number) => (
                <option key={number} value={number}>
                  {number}
                </option>
              ))}
            </select>
          </div>

          <div className="ml-4 flex">
            <div>
              <span className="text-black  text-xs font-normal leading-[15px] text-[15px]">{`${firstItemIndex}-${lastItemIndex} of ${data.length}`}</span>
            </div>

            <div className="ml-4 mt-1">
              <button
                onClick={() => paginate(Math.max(1, currentPage - 1))}
                disabled={currentPage === 1}
                className="text-black   font-normal leading-[15px] text-[25px]"
              >
                {"<"}
              </button>
              <button
                onClick={() => paginate(Math.min(totalPages, currentPage + 1))}
                disabled={currentPage === totalPages}
                className="text-black  font-normal leading-[15px] text-[25px]"
              >
                {">"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-16">
      <table className="min-w-max w-full table-auto ">
        <thead className="py-3 px-6 text-left text-neutral-500 text-[13px] font-extrabold leading-none">
          <tr>
            {columns.map((columns) => (
              <th className="py-3 px-6 text-left whitespace-nowrap font-extrabold">
                {columns.Header} <KeyboardArrowDownIcon/>
              </th>
            ))}


          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light">

            <tr

              className="border-b border-gray-200 hover:bg-gray-100 text-left"
            >
              <td className="py-3 px-6 text-left whitespace-nowrap">
              sustinext new
              </td>
              <td className="py-3 px-6 text-left whitespace-nowrap">
              GHG Accounting Report
              </td>
              <td className="py-3 px-6 text-left whitespace-nowrap">
              2022-01-06
              </td>
              <td className="py-3 px-6 text-left whitespace-nowrap">
              2022-12-06
              </td>

              <td className="py-3 px-6 text-left whitespace-nowrap">
              2024-02-06
              </td>
              <td className="py-3 px-6 text-left whitespace-nowrap">

              <a href="https://sustainextstorage1.blob.core.windows.net/sustainext/new-report-file.pdf" target="_blank" download="filename.pdf"> <DownloadIcon/></a>
              </td>
            </tr>

        </tbody>
      </table>


    </div>  */}

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg ml-40">
            <div className="div">
              <div className="mb-2 flex justify-center items-center">
                <WarningAmberOutlinedIcon
                  className="text-red-500"
                  fontSize="large"
                />
              </div>
              <div className="text-center mt-4 text-sm font-semibold mb-8">
                Are you sure you want to delete this report ?
              </div>
            </div>

            <div className="flex ml-4">
              <div className="flex items-center px-4  mr-4 py-2 bg-red-500 text-white rounded-md shadow-md">
                <div className=" w-[50%] ">
                  <DeleteIcon />
                </div>
                <button
                  className="px-2 font-bold bg-red-500 text-white "
                  onClick={handleDelete}
                  // onClick={() =>
                  //     confirmDelete(
                  //      datadelete,

                  //     )
                  //   }
                >
                  Delete
                </button>
              </div>

              <button
                className="px-4 py-2 border border-red-500 w-[40%] text-red-500 font-bold rounded"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default TableWithPagination;
