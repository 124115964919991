import React, { useState, useEffect } from 'react';
import GeneralInfo from './Organization/GeneralInfo';
import BusinessActivities from './Organization/BusinessActivities';
import BusinessRelationships from './Organization/BusinessRelationships';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const Entity = () => {
  const token = useSelector((state) => state.auth.authToken);
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const dataParam = params.get('data');

    if (dataParam) {
      const decodedData = decodeURIComponent(dataParam);
      const parsedData = JSON.parse(decodedData);
      setData(parsedData);
    }
  }, [location]);

  const loginUser = useSelector((state) => state.auth.loginUser);

  const handleGeneralDetailsSubmit = async (event, data) => {
    event.preventDefault();

    const url = `${process.env.REACT_APP_BACKEND_URL}/corporate`;

    const payload = {
      name: data.generalDetails.name || 'Test Corp',
      corporatetype: data.generalDetails.type || 'Default',
      ownershipnature: data.generalDetails.ownership || 'Default',
      location_headquarters: data.generalDetails.location || null,
      phone: data.generalDetails.phone || null,
      mobile: data.generalDetails.mobile || null,
      website: data.generalDetails.website || null,
      fax: data.generalDetails.fax || null,
      employeecount: data.generalDetails.Empcount || 100,
      revenue: data.generalDetails.revenue || '100000',
      sector: data.generalDetails.sector || 'Default',
      subindustry: data.generalDetails.subIndustry || 'Default',
      address: data.addressInformation.street || "Bengaluru",
      Country: data.addressInformation.country || 'IN',
      state: data.addressInformation.state || 'Karnataka',
      city: data.addressInformation.city || 'Bengaluru',
      zipcode: data.addressInformation.zipCode || null,
      date_format: data.generalDetails.dateFormat || null,
      currency: data.generalDetails.currency || 'USD',
      timezone: data.generalDetails.timeZone || '+00:00',
      language: data.generalDetails.language || 'English',
      from_date: data.reportingPeriodInformation.fromDate || null,
      to_date: data.reportingPeriodInformation.toDate || null,
      legalform: '1',
      ownership: data.generalDetails.ownership || 'Default',
      group: null,
      location_of_headquarters: null,
      amount: null,
      type_of_business_activities: null,
      type_of_product: null,
      type_of_services: null,
      type_of_business_relationship: null,
      framework: data.reportingPeriodInformation.reportingFramework || 'GRI',
      organization_id: data.generalDetails.organisation || 'Acme Group',
    };

    const accessToken = localStorage.getItem("accessToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken.replace(/^"|"$/g, ''),
  };

    try {
      const response = await axios.post(url, payload, {
        headers: headers,
      });
      console.log('POST request successful:', response.data);
      navigate('../general/structure');
    } catch (error) {
      console.error('Error:', error);
    }
    console.log(payload, 'payload');
  };

  const handleGeneralDetailsEdit = async (event, data, id) => {
    event.preventDefault();

    const url = `${process.env.REACT_APP_BACKEND_URL}/corporate/${id}/`;

    const payload = {
      name: data.generalDetails.name || null,
      corporatetype: data.generalDetails.type || null,
      ownershipnature: data.generalDetails.ownership || null,
      ownership: data.generalDetails.ownership || null,
      phone: data.generalDetails.phone || null,
      mobile: data.generalDetails.mobile || null,
      website: data.generalDetails.website || null,
      fax: data.generalDetails.fax || null,
      employeecount: data.generalDetails.Empcount || null,
      subindustry: data.generalDetails.subIndustry || null,
      address: data.addressInformation.street || null,
      Country: data.addressInformation.country || null,
      state: data.addressInformation.state || null,
      city: data.addressInformation.city || null,
      zipcode: data.addressInformation.zipCode || null,
      date_format: data.generalDetails.dateFormat || null,
      currency: data.generalDetails.currency || null,
      timezone: data.generalDetails.timeZone || null,
      language: null,
      from_date: data.reportingPeriodInformation.fromDate || null,
      to_date: data.reportingPeriodInformation.toDate || null,
      active: true,
      no_of_employees: null,
      revenue: data.generalDetails.revenue || null,
      amount: null,
      sector: data.generalDetails.sector || null,
      ownership_and_legal_form: null,
      group: null,
      type_of_corporate_entity: null,
      location_headquarters: data.generalDetails.location || null,
      location_of_headquarters: data.generalDetails.location || null,
      sub_industry: null,
      type_of_business_activities: null,
      type_of_product: null,
      type_of_services: null,
      sdg: null,
      rating: null,
      certification: null,
      target: null,
      framework: data.reportingPeriodInformation.reportingFramework || 'GRI',
      username: loginUser || 'mahinder.singh@sustainext.ai',
    };

    const accessToken = localStorage.getItem("accessToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken.replace(/^"|"$/g, ''),
  };

    try {
      const response = await axios.put(url, payload, {
        headers: headers,
      });
      navigate('../general/structure');
      alert('Corporate Entity updated successfully');
    } catch (error) {
      console.error('Error:', error);
    }

    console.log(payload, 'payload');
  };

  const handleBusinessActivitiesSubmit = (event) => {
    event.preventDefault();
    // logic for submitting general details section
  };

  // const handleBusinessRelationshipsSubmit = (event) => {
  //   event.preventDefault();
  //   // logic for submitting general details section
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    // logic for submitting full form
  };
  return (
    <form onSubmit={handleSubmit} className='p-4 rounded-md m-0'>
      <GeneralInfo
        handleGeneralDetailsSubmit={
          data ? handleGeneralDetailsEdit : handleGeneralDetailsSubmit
        }
        heading={data ? 'Edit Corporate Details' : 'Corporate Entity Details'}
        editData={data}
      />
      {/* <BusinessActivities
        handleBusinessActivitiesSubmit={handleBusinessActivitiesSubmit}
      />
      <BusinessRelationships /> */}
    </form>
  );
};

export default Entity;
