import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// Async Thunks

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ username, password }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const url = `${REACT_APP_BACKEND_URL}/api/auth/login/`;
      const data = {
        username,
        password,
      };

      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        console.log(response, 'login response');
        const authToken = response.data.key;
        const accessToken = response.data.key.access;
        const refreshToken = response.data.key.refresh;
        localStorage.setItem('authTokens', JSON.stringify(authToken));
        localStorage.setItem('accessToken', JSON.stringify(accessToken));
        localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
        dispatch(setLoading(false));
        return authToken;
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      console.error('Error after login:', error);
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  'auth/verifyEmail',
  async (key, { rejectWithValue }) => {
    console.log({ key }, 'verify Email on store fired');
    try {
      const url = `${REACT_APP_BACKEND_URL}/api/auth/register/verify-email/`;
      const data = {
        key,
      };

      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        console.log('Email Verified', response);
        return;
      } else {
        throw new Error('Verification failed');
      }
    } catch (error) {
      console.error('Error on verification:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const logoutUser = createAsyncThunk('auth/logout', async (authToken) => {
  const accessToken = localStorage.getItem('accessToken')
  const headers = {
    Authorization: `Bearer ${accessToken.replace(/"/g, '')}`,
    'Content-Type': 'application/json',
  };

  try {
    const response = await axios.post(
      `${REACT_APP_BACKEND_URL}/api/auth/logout/`,
      null,
      { headers }
    );

    if (response.status === 200) {
      console.log('Logout response', response);
      localStorage.removeItem('authTokens');
      localStorage.clear();
      // You can dispatch any action here if needed
    } else {
      console.error('Logout failed with status:', response.status);
    }
  } catch (error) {
    console.log(error);
  }
});

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async ({ username, password, password2 }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        'http://sustainext.staging.azte.in/api/v1/accounts/register/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            password,
            password2,
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Registration failed');
      }

      return { success: true };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Slice
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    users: [],
    user: '',
    username: localStorage.getItem('name'),
    authToken: localStorage.getItem('authTokens') || null,
    loading: false,
    error: null,
    showModal: true,
  },

  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        // state.user = action.payload.user;
        state.user = action.payload;
        state.authToken = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.user = null;
        state.authToken = null;
        state.loading = false;
        state.error = null;
      });
  },
});

export const {
  setUsers,
  setUser,
  setUsername,
  setAuthToken,
  setLoading,
  setError,
  setShowModal,
} = authSlice.actions;
export default authSlice.reducer;
