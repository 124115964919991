import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TrackViewer = () => {
  const [trackUrl, setTrackUrl] = useState('');
  const [trackError, setTrackError] = useState('');
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
  const client_id = localStorage.getItem('client_id');

  useEffect(() => {
    const fetchTrackUrl = () => {
      if (!accessToken) {
        setTrackError('Authentication details not provided');
        return;
      }

      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BACKEND_URL}/sustainapp/zoho_info/${client_id}/`,
        headers: {
          'Authorization': `Bearer ${accessToken.replace(/^"|"$/g, '')}`,
        },
      };

      axios(config)
        .then(response => {
          console.log(JSON.stringify(response.data));
          setTrackUrl(response.data.iframe_url);
        })
        .catch(error => {
          console.error('Failed to fetch track URL:', error);
          setTrackError('Failed to load track data. Please try again.');
        });
    };

    fetchTrackUrl();
  }, [accessToken]);

  return (
    <div className="flex justify-center items-center h-screen">
      {trackUrl ? (
        <iframe
          style={{ border: 'none' }}
          width="1000"
          height="700"
          src={trackUrl}
          title="Track Viewer"
        ></iframe>
      ) : trackError ? (
        <p>{trackError}</p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default TrackViewer;
