import React, { useState, useEffect } from 'react';
import { useProSidebar } from "react-pro-sidebar";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const NewTooltip = ({ tooltiptext }) => {


    return (
        <Tooltip title={tooltiptext} arrow placement="top" componentsProps={{
            tooltip: {
                sx: {
                    backgroundColor: '#000',
                    color: 'white',
                    fontSize: '12px',
                    boxShadow: 3,
                    borderRadius: "8px"
                },
            },
            arrow: {
                sx: {
                    color: '#000',
                },
            },
        }}>
            <InfoOutlinedIcon sx={{ fontSize: "14px", marginLeft: "5px" ,color:"#000" }} />
        </Tooltip>
    );
};
const Managementwasteimpact = () => {
    const { collapsed } = useProSidebar();
    return (
        <>
            <div className='mt-3'>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[65%]' : 'w-[80%]'}`}>
                            <h6 className='text-md ont-medium text-[#727272]'>
                            Actions taken to prevent waste generation and to manage significant impacts from waste generated
                                <NewTooltip tooltiptext="Include: Actions mentioned should include actions including circularity measures." />
                            </h6>
                            <h6 className='text-[#727272] text-[12px]'>Describe actions taken to prevent waste generated from organization's own activities, upstream
activities and downstream activities in its value chain</h6>
                        </div>
                        <div className={`absolute  flex justify-between -mt-1 ${collapsed ? 'right-[11rem]' : 'right-[4.5rem]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-blue-800 text-[10px] inline-block align-middle px-2 font-semibold">GRI 306-2a</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'w-[48rem]' : 'w-[44.2rem]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[65%]' : 'w-[80%]'}`}>
                            <h6 className='text-md font-medium text-[#727272]'>
                            Waste Management by Third-party
                                <NewTooltip tooltiptext="This section documents the data corresponding to the waste generated by the organization in its own activities is managed by a third party. "/>
                            </h6>
                            <h6 className='text-[#727272] text-[12px]'>rocesses used to determine whether the third party manages the waste
in line with contractual or legislative obligations.
<NewTooltip tooltiptext="Third-party: Third party includes a public or private waste management organization, or any other entity or group of individuals formally or informally involved in handling the reporting organization’s waste. Include: Agreements in a contract for the third party to follow when managing its waste, or rely on existing legislative obligations, such as local environmental laws and regulations. "/>
</h6>
                        </div>
                        <div className={`absolute  flex justify-between -mt-1 ${collapsed ? 'right-[11rem]' : 'right-[4.5rem]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-blue-800 text-[10px] inline-block align-middle px-2 font-semibold">GRI 306-2b</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'w-[48rem]' : 'w-[44.2rem]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[65%]' : 'w-[80%]'}`}>
                            <h6 className='text-md font-medium text-[#727272]'>
                            Waste-related data management
                                <NewTooltip tooltiptext="This section documents the data corresponding to the processes used to collect and monitor waste-related data."/>
                            </h6>
                            <h6 className='text-[#727272] text-[12px]'>Describe the processes used to collect and monitor waste-related data
<NewTooltip tooltiptext="Third-party: Third party includes a public or private waste management organization, or any other entity or group of individuals formally or informally involved in handling the reporting organization’s waste. Include: Agreements in a contract for the third party to follow when managing its waste, or rely on existing legislative obligations, such as local environmental laws and regulations. "/>
</h6>
                        </div>
                        <div className={`absolute  flex justify-between -mt-1 ${collapsed ? 'right-[11rem]' : 'right-[4.5rem]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-blue-800 text-[10px] inline-block align-middle px-2 font-semibold">GRI 306-2b</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'w-[48rem]' : 'w-[44.2rem]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
export default Managementwasteimpact;