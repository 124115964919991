import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import ProfileImage from "components/profileimge/ProfileImage";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ToastContainer, toast } from "react-toastify";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
const CustomModal = ({ onClose, children }) => {
  const username = useSelector((state) => state.global.username);
  const fullName = localStorage.getItem("name");
  const [firstName, lastName] = fullName.split(".");
  const nspace = "108px";
  const ntextsize = "30px";
  const [name, setName] = useState();
  const [designation, setDesignation] = useState();
  const [department, setDepartment] = useState();
  const [phone, setPhone] = useState();
  const id = parseInt(localStorage.getItem("user_id"));
  const [loopen, setLoOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [newfirstName, setFirstName] = useState();
  const [newlastName, setLastName] = useState();
  const isMounted = useRef(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
  const fetchUserDetails = async () => {
    LoaderOpen();
    const userId = localStorage.getItem("user_id");
    try {
      const accessToken = localStorage.getItem("accessToken");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken.replace(/^"|"$/g, ''),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/user_details/?user_id=${userId}`,
        { headers }
      );

      // Handle the successful response
      setFirstName(response.data[0].first_name);
      setLastName(response.data[0].last_name);
      setDesignation(response.data[0].designation);
      setDepartment(response.data[0].department);
      setPhone(response.data[0].phone);
      setName(response.data[0].first_name + " " + response.data[0].last_name);
      LoaderClose();
    } catch (error) {
      setIsModalOpen(true);
      LoaderClose();
    }
  };

  if (isMounted.current) {
    fetchUserDetails();
    isMounted.current = false;
  }

  return () => {
    isMounted.current = false;
  };
}, []);


  const handleShow = () => {
    setIsShow(!isShow);
  };
  const LoaderOpen = () => {
    setLoOpen(true);
  };
  const LoaderClose = () => {
    setLoOpen(false);
  };
  const handleOkclose = () => {
    setIsModalOpen(false);
    onClose();
  };
  const handleName = (e) => {
    const inputName = e.target.value;
    setName(inputName);
    const parts = inputName.trim().split(" ");
    if (parts.length >= 2) {
      setFirstName(parts[0]);
      setLastName(parts[parts.length - 1]);
    } else {
      setFirstName(inputName); // Set the entire input as the first name if there's no space
      setLastName(""); // Reset the last name if there's no space
    }
  };
  const handleMobile = (e) => {
    setPhone(e.target.value);
  };
  const handleDepartment = (e) => {
    setDepartment(e.target.value);
  };
  const handletDesignation = (e) => {
    setDesignation(e.target.value);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    LoaderOpen();
  
    const sandData = {
      designation: designation,
      department: department,
      profile_picture: null,
      name: name,
      phone: phone,
    };
  
    const accessToken = localStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken.replace(/^"|"$/g, ''),
    };
  
    await axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/user_profile_update/?user_id=${id}`,
        sandData,
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Profile updated successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          LoaderClose();
          onClose();
        } else {
          toast.error("Error", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          LoaderClose();
        }
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        toast.error("Error", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        LoaderClose();
      });
  };
  

  return ReactDOM.createPortal(
    <>
      {/* Background overlay */}
      <ToastContainer style={{ fontSize: "12px" }} />
      <div className="fixed top-0 left-0 w-full h-full bg-gray-900 opacity-60 z-40"></div>

      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 welcome-screen">
   
          {/* Modal content */}

          {isModalOpen ? (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 welcome-screen">
          <div className="modal-content relative  bg-white rounded-md p-0 w-[365px] h-[200px]">
            <div className="py-4">
              <div>
                <WarningAmberOutlinedIcon sx={{ color: "#ffd11a",fontSize:"50px" }} />
              </div>
              <div className="mt-5">
                <h3 className="text-center px-3">
                Unable to load data. Please contact Administrator
                </h3>
              </div>
              <div className="mt-3">
                <button
                  onClick={handleOkclose}
                  className="w-[40%] h-[31px]  px-[22px] py-2 mb-4 bg-blue-500 text-white rounded shadow flex-col justify-center items-center inline-flex cursor-pointer "
                >
                  ok
                </button>
              </div>
            </div>
          </div>
        </div>
           ) : (
            <div className="modal-content relative flex bg-white rounded-md p-0 w-[645px] h-[329px]">
            <button
              onClick={() => onClose("customModal")}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <Close />
            </button>
            <div className="p-4 ps-8 w-full">
            <div className="flex justify-between items-center mb-8">
              <h5
                className="text-black text-[18px] font-medium leading-relaxed cursor-pointer"
                onClick={handleShow}
              >
                <EditOutlinedIcon /> Edit Profile
              </h5>
            </div>
            <div className="flex  space-x-[60px] ps-4 h-[329px]">
              <div className="mt-10">
                <ProfileImage
                  firstName={firstName}
                  lastName={lastName}
                  spacing={nspace}
                  textsize={ntextsize}
                />
              </div>

              <div>
                {isShow ? (
                  <form onSubmit={submitForm}>
                    <div className="w-full h-8 flex space-x-6 my-3 items-center">
                      <div className="w-36 text-left text-neutral-500 text-xs font-semibold uppercase leading-relaxed tracking-wide">
                        Name
                      </div>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        value={name}
                        placeholder="Enter Name"
                        className="self-stretch text-neutral-500 text-sm px-2 font-normal border w-full"
                        onChange={handleName}
                      />
                    </div>
                    <div className="w-full h-8 flex space-x-6 my-3 items-center">
                      <div className="w-36 text-left text-neutral-500 text-xs font-semibold uppercase leading-relaxed tracking-wide">
                        Designation
                      </div>
                      <input
                        id="designation"
                        name="designation"
                        type="text"
                        value={designation}
                        placeholder="Enter Designation"
                        className="self-stretch text-neutral-500 text-sm px-2 font-normal border w-full"
                        onChange={handletDesignation}
                      />
                    </div>
                    <div className="w-full h-8 flex space-x-6 my-3 items-center">
                      <div className="w-36 text-left text-neutral-500 text-xs font-semibold uppercase leading-relaxed tracking-wide">
                        Department
                      </div>
                      <input
                        id="department"
                        name="department"
                        type="text"
                        placeholder="Enter department"
                        value={department}
                        className="self-stretch text-neutral-500 text-sm px-2 font-normal border w-full"
                        onChange={handleDepartment}
                      />
                    </div>
                    <div className="w-full h-8 flex space-x-6 my-3 items-center">
                      <div className="w-36 text-left text-neutral-500 text-xs font-semibold uppercase leading-relaxed tracking-wide">
                        Phone
                      </div>
                      <input
                        id="phone"
                        name="phone"
                        type="number"
                        placeholder="Enter phone Number"
                        className="self-stretch text-neutral-500 text-sm px-2 font-normal border w-full"
                        value={phone}
                        onChange={handleMobile}
                      />
                    </div>
                    <div className="flex space-x-6 justify-center mt-4">
                      <input
                        type="submit"
                        className="w-[40%] h-[31px]  px-[22px] py-2 bg-blue-500 text-white rounded shadow flex-col justify-center items-center inline-flex cursor-pointer "
                        value="Save"
                      />
                    </div>
                  </form>
                ) : (
                  <>
                    <div className="w-full h-8 flex space-x-6 my-3 items-center">
                      <div className="w-36 text-left text-neutral-500 text-xs font-semibold uppercase leading-relaxed tracking-wide">
                        Name
                      </div>
                      <div className="w-56 h-[31px] text-left text-neutral-500 text-xs font-semibold  leading-relaxed tracking-wide border px-2 py-1">
                        {newfirstName} {newlastName}
                      </div>
                    </div>
                    <div className="w-full h-8 flex space-x-6 my-3 items-center">
                      <div className="w-36  text-left text-neutral-500 text-xs font-semibold uppercase leading-relaxed tracking-wide ">
                        Designation
                      </div>
                      <div className="w-56 h-[31px] text-left text-neutral-500 text-xs font-semibold  leading-relaxed tracking-wide border px-2 py-1">
                        {designation}
                      </div>
                    </div>
                    <div className="w-full h-8 flex space-x-6 my-3 items-center">
                      <div className="w-36  text-left text-neutral-500 text-xs font-semibold uppercase leading-relaxed tracking-wide">
                        Department
                      </div>
                      <div className="w-56 h-[31px] text-left text-neutral-500 text-xs font-semibold  leading-relaxed tracking-wide border px-2 py-1">
                        {department}
                      </div>
                    </div>
                    <div className="w-full h-8 flex space-x-6 my-3 items-center">
                      <div className="w-36 text-left text-neutral-500 text-xs font-semibold uppercase leading-relaxed tracking-wide">
                        Phone
                      </div>
                      <div className="w-56 h-[31px] text-left text-neutral-500 text-xs font-semibold  leading-relaxed tracking-wide border px-2 py-1">
                        {phone}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          </div>
      )}
          
        
      </div>
   
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>,

    document.body
  );
};

export default CustomModal;
