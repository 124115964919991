import React, { useState, useEffect, useRef } from "react";
import { AddOutlined } from "@mui/icons-material";
import AddTaskIcon from "@mui/icons-material/AddTask";
import DeleteIcon from "@mui/icons-material/Delete";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Moment from "react-moment";
import { useSelector } from "react-redux";

const CreateTask = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loopen, setLoOpen] = useState(false);
  const userId = useSelector((state) => state.global.userId);
  const isMounted = useRef(true);
  const [goals, setGoals] = useState({});
  const [activeTab, setActiveTab] = useState("upcoming");
  const [addgoles, setaddgoles] = useState({
    taskname: "",
    deadline: "",
  });

  const { taskname, deadline } = addgoles;
  const getTodayDate = () => {
    const today = new Date();
    let month = "" + (today.getMonth() + 1);
    let day = "" + today.getDate();
    const year = today.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const LoaderOpen = () => {
    setLoOpen(true);
  };
  const LoaderClose = () => {
    setLoOpen(false);
  };
  const handleCompleted = async (id) => {
    LoaderOpen();
    const sandData = {
      completed: true,
    };

    const accessToken = localStorage.getItem('accessToken');

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken.replace(/"/g, '')}`
        }
    };

    await axios
      .patch(`${process.env.REACT_APP_BACKEND_URL}/mytask/${id}/`, sandData, config)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Task has been completed successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          LoaderClose();
          fetchMytaskDetails();
        } else {
          toast.error("Error", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          LoaderClose();
        }
      });
};

  const handleDelete = async (id) => {
    LoaderOpen();
    const accessToken = localStorage.getItem('accessToken');

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken.replace(/"/g, '')}`
        }
    };
    await axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/mytask/${id}/`,config)
      .then((response) => {
        if (response.status == "200") {
          console.log(response.status);
          toast.success("Task  has been delete successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          LoaderClose();
          fetchMytaskDetails();
        } else {
          toast.error("Error", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          LoaderClose();
        }
      });
  };
  const fetchMytaskDetails = async () => {
    // console.log("user id ", localStorage.getItem("user_id"));
    const userId = localStorage.getItem("user_id");
    const accessToken = localStorage.getItem('accessToken');

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken.replace(/"/g, '')}`
        }
    };
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/mytask/?assigned_to=${userId}`,
      config
    );
    // console.log(response.data.data, "my gole");
    setGoals(response.data);
  };

  useEffect(() => {
    if (isMounted.current) {
      fetchMytaskDetails();
      isMounted.current = false;
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const datahandleChange = (e) => {
    setaddgoles({ ...addgoles, [e.target.name]: e.target.value });
  };
  const submitForm = async (e) => {
    e.preventDefault();
    LoaderOpen();
    const accessToken = localStorage.getItem('accessToken')
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken.replace(/"/g, '')}`
      },
    };

    const sandData = {
      ...addgoles,
      assigned_to: parseInt(localStorage.getItem("user_id")),
      completed: false,
    };
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/mytask/`, sandData, options)
      .then((response) => {
        if (response.status == "200") {
          console.log(response.status);
          toast.success("Task has been added successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          LoaderClose();
          handleCloseModal();
          fetchMytaskDetails();
          setaddgoles({});
        } else {
          toast.error("Error", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          LoaderClose();
        }
      });
    //console.log(sandData);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="rounded-lg shadow border border-gray-200 p-4 h-[320px] ">
        <div className="flex justify-between mb-4">
          <div className="text-neutral-800 text-[15px] font-bold leading-tight">
            My Task
          </div>

          <div
            className="text-sky-600 text-[10px] cursor-pointer font-normal leading-[13px] flex items-center me-2 space-x-2"
            onClick={handleOpenModal}
          >
            <AddOutlined style={{ fontSize: "18px" }} />
            <span>Add task</span>
          </div>
        </div>
        <div>
          <div className={`flex my-6 border-b text-sm text-start`}>
            <button
              className={`pr-2 py-1 rounded-b-none text-xs font-bold leading-[15px] ${
                activeTab === "upcoming"
                  ? "border-b-2 border-[#1aaef4] text-[#1aaef4]"
                  : "border-transparent text-neutral-500"
              }`}
              onClick={() => setActiveTab("upcoming")}
            >
              Upcoming
            </button>
            <button
              className={`px-4 py-1 rounded-b-none text-xs font-bold leading-[15px] ${
                activeTab === "overdue"
                  ? "border-b-2 border-[#1aaef4] text-[#1aaef4]"
                  : "border-transparent text-neutral-500"
              }`}
              onClick={() => setActiveTab("overdue")}
            >
              Overdue
            </button>
            <button
              className={`px-4 py-1 rounded-b-none text-xs font-bold leading-[15px] ${
                activeTab === "completed"
                  ? "border-b-2 border-[#1aaef4] text-[#1aaef4]"
                  : "border-transparent text-neutral-500"
              }`}
              onClick={() => setActiveTab("completed")}
            >
              Completed
            </button>
          </div>

          <div className="p-4 h-[188px]  overflow-scroll">
            {activeTab === "upcoming" && (
              <div>
                {goals.upcoming == "" ? (
                  <div className="justify-center items-center ">
                    <div className="flex justify-center items-center pb-5">
                      <AddTaskIcon
                        sx={{ color: "#ACACAC", fontSize: "36px" }}
                      />
                    </div>
                    <div>
                      <p className="text-[14px] text-[#101828] font-bold text-center">
                        Start by creating a task
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="text-[12px] text-[#667085] text-center">
                        All task created or assigned to you will be here
                      </p>
                    </div>
                    <div className="flex justify-center items-center">
                      <button
                        className="bg-[#007EEF] text-white w-[150px] p-1 rounded-md shadow-md"
                        onClick={handleOpenModal}
                      >
                        Add a task
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="space-y-3 mb-6 mt-2 ">
                      {goals.upcoming &&
                        goals.upcoming.map((task) => (
                          <>
                            <div className="flex justify-between">
                              <div className="flex cursor-pointer">
                                <div>
                                  <CircleOutlinedIcon
                                    sx={{ fontSize: "21px", mt: -1.3 }}
                                    onClick={() => handleCompleted(task.id)}
                                  />
                                </div>
                                <div className="w-auto text-neutral-800 text-[13px] font-normal leading-none ml-3 ">
                                  {task.taskname}
                                </div>
                              </div>
                              <div className="flex">
                                <div className="w-[68px] text-neutral-500 text-xs font-normal leading-[15px]">
                                  <Moment format="DD/MM/YYYY">
                                    {task.deadline}
                                  </Moment>
                                </div>
                                <div
                                  className="w-[18px] cursor-pointer "
                                  // onClick={handleDeleteGoal}
                                >
                                  <DeleteIcon
                                    sx={{
                                      color: "#0000008F",
                                      fontSize: "18px",
                                      mt: -1,
                                    }}
                                    onClick={() => handleDelete(task.id)}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            )}

            {activeTab === "overdue" && (
              <div>
                {goals.overdue == "" ? (
                  <div className="h-screen justify-center items-center ">
                    <h4 className="text-center">No data found</h4>
                  </div>
                ) : (
                  <div>
                    <div className="space-y-3 mb-6 nt-2">
                      {goals.overdue &&
                        goals.overdue.map((task) => (
                          <>
                            {task.completed == false ? (
                              <>
                                <div className="flex justify-between">
                                  <div className="flex cursor-pointer">
                                    <div>
                                      <CircleOutlinedIcon
                                        sx={{
                                          fontSize: "21px",
                                          mt: -1.1,
                                          color: "#cc0000",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => handleCompleted(task.id)}
                                      />
                                    </div>
                                    <div className="w-auto text-red-600 text-[13px] font-normal leading-none ml-3 ">
                                      {task.taskname}
                                    </div>
                                  </div>
                                  <div className="flex">
                                    <div className="w-[68px] text-red-600 text-xs font-normal leading-[15px]">
                                      <Moment format="DD/MM/YYYY">
                                        {task.deadline}
                                      </Moment>
                                    </div>
                                    <div
                                      className="w-[18px] cursor-pointer "
                                      // onClick={handleDeleteGoal}
                                    >
                                      <DeleteIcon
                                        sx={{
                                          color: "#cc0000",
                                          fontSize: "18px",
                                          mt: -1,
                                        }}
                                        onClick={() => handleDelete(task.id)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {activeTab === "completed" && (
              <div>
                {goals.completed == "" ? (
                  <div className="h-screen justify-center items-center ">
                    <h4 className="text-center">No data found</h4>
                  </div>
                ) : (
                  <div>
                    <div className="space-y-3 mb-6 mt-2">
                      {goals.completed &&
                        goals.completed.map((task) => (
                          <>
                            {task.completed == true ? (
                              <div className="flex justify-between">
                                <div className="flex">
                                  <div>
                                    <CheckCircleIcon
                                      sx={{
                                        fontSize: "21px",
                                        mt: -1.3,
                                        color: "#3DCA7C",
                                      }}
                                    />
                                  </div>
                                  <div className="w-auto text-neutral-800 text-[13px] font-normal leading-none ml-3 ">
                                    {task.taskname}
                                  </div>
                                </div>
                                <div className="flex">
                                  <div className="w-[68px] text-neutral-500 text-xs font-normal leading-[15px]">
                                    <Moment format="DD/MM/YYYY">
                                      {task.deadline}
                                    </Moment>
                                  </div>
                                  {/* <div
                                      className="w-[18px] cursor-pointer "
                                      // onClick={handleDeleteGoal}
>
<DeleteIcon
                                        sx={{
                                          color: "#0000008F",
                                          fontSize: "18px",
                                          mt: -1,
                                        }}
                                        onClick={()=>handleDelete(task.id)}
                                      />
</div> */}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="px-[5px] py-1 rounded flex-col justify-center items-center inline-flex">
          <div className="justify-center items-center gap-2 inline-flex">
            <div className="h-[18px] flex-col justify-center items-center inline-flex">
              <div className="w-[18px] h-[18px] relative flex-col justify-start items-start flex" />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-overlay z-50">
          <div className="modal-center">
            <div className="modal-content">
              <div className="flex justify-between items-center drop-shadow-lg border-b-2 py-6 w-full">
                <h2 className="self-stretch text-black text-opacity-90 text-[22px] font-normal leading-relaxed flex space-x-8 items-center ms-6">
                  <span>Add task</span>
                </h2>
                <button
                  className="absolute top-2 right-2 mt-4 text-gray-500 hover:text-gray-700 focus:outline-none"
                  onClick={handleCloseModal}
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="my-6 mx-8 ">
                <div className="mb-2 py-4 px-3">
                  <div>
                    <form class="w-full text-left" onSubmit={submitForm}>
                      <div className="mr-2 mb-4 w-[101%]">
                        <label
                          htmlFor="cname"
                          className="block text-neutral-800 text-[13px] font-normal"
                        >
                          Task name
                        </label>

                        <div className="mt-2 mr-2">
                          <input
                            id="title"
                            title="title"
                            type="text"
                            name="taskname"
                            autoComplete="off"
                            required
                            placeholder="Enter Task Title"
                            onChange={datahandleChange}
                            value={taskname}
                            className="block  w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="flex ">
                        <div className="col-span-2 mb-4 flex-1">
                          <div>
                            <label
                              htmlFor="dateField"
                              className="block text-neutral-800 text-[13px] font-normal"
                            >
                              Deadline
                            </label>
                            <div className="mt-2 ">
                              <input
                                id="deadline"
                                title="deadline" // Use name instead of title
                                type="date"
                                name="deadline"
                                autoComplete="off"
                                onChange={datahandleChange}
                                value={deadline}
                                min={getTodayDate()}
                                required
                                className="block w-full px-1 rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-span-2 mb-4 flex-1">
<div className="mr-2">
<label
                        htmlFor="sdate"
                        className="block text-neutral-800 text-[13px] font-normal"
>
                        Assign To
</label>
<div className="mt-2 ">
<select
                          className="block w-full rounded-md border-0 py-2 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          name="Reporttype"
                          placeholder="Enter the Goals Deadline"
                          value={formData.category}
                          onChange={handleInputChange}
>
<option value="" disabled selected>
                            Enter the Goals Deadline
</option>
<option>GHG Accounting Report</option>
<option>GRI</option>
<option>TCFD</option>
<option>SASB</option>
<option>BRSR</option>
</select>
</div>
</div>
</div> */}
                      </div>
                      <div className="flex justify-center mt-5">
                        <input
                          type="submit"
                          value="Save"
                          className="w-[30%] h-[31px]  px-[22px] py-2 bg-blue-500 text-white rounded shadow flex-col justify-center items-center inline-flex cursor-pointer"
                          // onClick={handleSave}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CreateTask;
