import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import {
  FilePresent,
  ArrowDropDown,
  KeyboardArrowDownOutlined,
  DeleteOutline,
  MoreVert,
  EditOutlined,
  SaveOutlined,
  FileUploadOutlined,
} from '@mui/icons-material';
import PdfPreviewModal from './PdfPreviewModal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setRefreshPage } from 'state/emissionSlice';
import ConfirmationModal from 'components/react-modal/confirmModal';
import { unitTypes as units } from 'components/data/units';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function ShowEmission({ data, scope, scopeInfo }) {
   //loader
   const [loopen, setLoOpen] = useState(false);
   const LoaderOpen = () => {
     setLoOpen(true);
   };
   const LoaderClose = () => {
     setLoOpen(false);
   };
  const [rows, setRows] = useState(data);
  const [uploadedFile, setUploadedFile] = useState(null); // New state variable to store uploaded file
  const refreshPage = useSelector((state) => state.emission.refreshPage);
  const dispatch = useDispatch();

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const handleRowDelete = (id) => {
    LoaderOpen();
    setRowToDelete(id);
    setIsDeleteConfirmationOpen(true);
    confirmDelete();
    LoaderClose();
  };

  const confirmDelete = () => {
    if (rowToDelete) {
      const accessToken = localStorage.getItem("accessToken");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken.replace(/^"|"$/g, ''),
      };
  
      axios
        .delete(
          `${process.env.REACT_APP_BACKEND_URL}/emissions/${rowToDelete}`,
          { headers } // Pass the headers as the third parameter
        )
        .then(() => {
          console.log("Deletion was successful");
          // Handle any other post-deletion logic here
        })
        .catch((error) => {
          console.error("Error deleting row:", error);
        })
        .finally(() => {
          setIsDeleteConfirmationOpen(false);
          setRowToDelete(null);
          dispatch(setRefreshPage(!refreshPage));
        });
    }
  };
  

  const closeDeleteConfirmation = () => {
    setRowToDelete(null);
    setIsDeleteConfirmationOpen(false);
  };

  const [showDropdown, setShowDropdown] = useState(
    new Array(data?.length).fill(false)
  );

  useEffect(() => {
    setShowDropdown(new Array(data?.length).fill(false));
  }, [data]);

  const toggleRowDropdown = (index) => {
    const updatedShowDropdown = [...showDropdown];
    updatedShowDropdown[index] = !updatedShowDropdown[index];
    setShowDropdown(updatedShowDropdown);
  };

  const dropdownRef = useRef(null);

  const [editRowIndex, setEditRowIndex] = useState(null);
  const [editRow, setEditRow] = useState({
    id: '',
    value1: null,
    unit_type: '',
    unit1: '',
    value2: null,
    unit2: '',
    assign_to: '',
    sector: '',
    category: '',
    activity_rowbatchdata: {
      name: '',
      activity_id: '',
      emmissionfactorid: '',
      id: '',
    },
  });

  const handleUpdateClick = (index, row) => {
    setEditRowIndex(index);
    toggleRowDropdown(index);
    // console.log('row in update: ' + JSON.stringify(row));
    setEditRow({
      id: row.id,
      value1: row.value1,
      unit_type: row.unitType,
      unit1: row.unit[0],
      value2: row.value2,
      unit2: row.unit[1],
      assign_to: row.assignTo,
      sector: row.category,
      category: row.subCategory,
      activity_rowbatchdata: {
        name: row.name,
        activity_id: row.activity_id,
        emmissionfactorid: row.emmissionfactorid,
        id: row.emmissionfactorid,
      },
    });
  };

  const handleChange = (event, field) => {
    console.log('handleChange triggered', scope);
    const newEditRow = { ...editRow };

    if (field === 'assignTo') {
      newEditRow.assign_to = event.target.value;
    } else if (field === 'unit1') {
      newEditRow.unit1 = event.target.value;
    } else if (field === 'unit2') {
      newEditRow.unit2 = event.target.value;
    } else if (field === 'value1') {
      newEditRow.value1 = event.target.value;
    } else if (field === 'value2') {
      newEditRow.value2 = event.target.value;
    } else if (field === 'file') {
      const uploadedFile = event.target.files[0];
      console.log('uploaded File', uploadedFile);

      if (uploadedFile) {
        const reader = new FileReader();
        reader.readAsDataURL(uploadedFile);
        reader.onload = (event) => {
          console.log(event.target.result, uploadedFile.name, 'afterload');
          newEditRow.file = event.target.result;
          newEditRow.fileName = uploadedFile.name;
          newEditRow.modifiedTime = new Date().toLocaleString();
        };
      }
    }

    setEditRow(newEditRow);
  };

  const saveHandler = () => {
    LoaderOpen();
    // Construct the payload
    const payload = {
      id: editRow.id,
      value1: parseFloat(editRow.value1),
      unit_type:
        editRow.unit_type.charAt(0).toUpperCase() + editRow.unit_type.slice(1),
      unit1: editRow.unit1,
      assign_to: editRow.assign_to,
      sector: editRow.sector,
      category: editRow.category,
      activity_rowbatchdata: {
        name: editRow.activity_rowbatchdata?.name,
        activity_id: editRow.activity_rowbatchdata.activity_id,
        emmissionfactorid: editRow.activity_rowbatchdata.emmissionfactorid,
        id: editRow.activity_rowbatchdata.id,
      },
    };

    if (editRow.value2) {
      payload.value2 = parseFloat(editRow.value2);
      payload.unit2 = editRow.unit2 || '';
    }

    // console.log({ payload });

    const accessToken = localStorage.getItem("accessToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken.replace(/^"|"$/g, ''),
  };

    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/emissions/${payload.id}/`,
        payload,
      { headers }
      )
      .then((response) => {
        console.log('Update successful:', response.data, editRow);
        LoaderClose();
      })
      .catch((error) => {
        LoaderClose();
        console.error('Error updating data:', error, editRow);
      })
      .finally(() => {
        setEditRowIndex(-1);
        dispatch(setRefreshPage(!refreshPage));
      });
  };

  // Preview

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewRowIndex, setPreviewRowIndex] = useState(null);

  const handleGreenIconClick = (index) => {
    setPreviewRowIndex(index);
    setShowPreviewModal(true);
  };

  const closeModal = () => {
    setShowPreviewModal(false);
  };

  // Decode file

  function base64ToRawFile(base64String, fileName) {
    // Decode the base64 string into a binary data buffer
    const binaryString = atob(base64String);

    // Create a Uint8Array from the binary data
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the binary data
    const blob = new Blob([uint8Array], { type: 'application/octet-stream' });

    // Create a download link for the Blob
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Trigger a click event to download the file
    link.click();
  }

  return (
    data && (
      <>
      <div className='w-full'>
        <table className='w-full text-xs text-start text-[#707070]'>
          <tbody>
            {data?.map((row, index) => (
              <tr key={index} className='border-b border-[#EDEAE9] relative'>
                <td className='w-[17.25%]'>
                  <div className='relative'>
                    <select
                      className='cursor-pointer appearance-none bg-white px-4 py-2 rounded leading-tight outline-none m-[3px] w-full truncate'
                      disabled={editRowIndex !== index}
                    >
                      <option value=''>{row.category}</option>
                    </select>
                    <div className='absolute inset-y-0 right-0 flex items-center pointer-events-none'>
                      <KeyboardArrowDownOutlined
                        className='text-neutral-500'
                        style={{ fontSize: '20px' }}
                      />
                    </div>
                  </div>
                </td>
                <td className='w-[17.25%]'>
                  <div className='relative'>
                    <select
                      className='cursor-pointer appearance-none bg-white px-4 py-2 rounded leading-tight outline-none m-[3px] w-full truncate'
                      disabled={editRowIndex !== index}
                    >
                      <option value=''>{row.subCategory}</option>
                    </select>
                    <div className='absolute inset-y-0 -right-2 flex items-center pointer-events-none'>
                      <KeyboardArrowDownOutlined
                        className='text-neutral-500'
                        style={{ fontSize: '20px' }}
                      />
                    </div>
                  </div>
                </td>
                <td className='w-[17.25%]'>
                  <div className='relative'>
                    <select
                      className='cursor-pointer appearance-none bg-white px-4 py-2 rounded leading-tight outline-none m-[3px] w-full'
                      disabled={editRowIndex !== index}
                    >
                      <option value=''>
                        {row.activity_name} - {row.unitType}
                      </option>
                    </select>
                    <div className='absolute inset-y-0 -right-2 flex items-center pointer-events-none'>
                      <KeyboardArrowDownOutlined
                        className='text-neutral-500'
                        style={{ fontSize: '20px' }}
                      />
                    </div>
                  </div>
                </td>

                {!row?.unitType?.includes('over') ? (
                  <td className='relative w-[27.21%]'>
                    <div className='flex w-full'>
                      <div className='flex-grow'>
                        <input
                          type='number'
                          step='0.01'
                          className='w-full p-2 rounded-sm mt-1 ms-2 alignment'
                          value={
                            editRowIndex === index ? editRow.value1 : row.value1
                          }
                          onChange={(event) => handleChange(event, 'value1')}
                          disabled={editRowIndex !== index}
                        />
                      </div>
                      <div className='absolute right-0 top-0.5'>
                        <select
                          value={
                            editRowIndex === index ? editRow.unit1 : row.unit[0]
                          }
                          onChange={(event) => handleChange(event, 'unit1')}
                          className={`cursor-pointer appearance-none px-2 py-1 rounded-md leading-tight outline-none ms-1 mt-1.5 font-bold text-xs ${
                            row.unit[0]
                              ? 'text-sky-600 bg-white drop-shadow-md'
                              : 'bg-sky-600 text-white'
                          }`}
                          style={{ width: '66px' }}
                          disabled={editRowIndex !== index}
                        >
                          <option className='text-xs'>{row.unit[0]}</option>
                          {row.unitType &&
                            units
                              .filter(
                                (unit) =>
                                  unit.unit_type ===
                                  row.unitType.charAt(0).toUpperCase() +
                                    row.unitType.slice(1)
                              )
                              .reduce((combinedUnits, unit) => {
                                return combinedUnits.concat(
                                  Object.values(unit.units)
                                );
                              }, [])
                              .flat()
                              .map((unitName) => (
                                <option key={unitName} className='text-xs'>
                                  {unitName}
                                </option>
                              ))}
                        </select>
                        <span className='absolute right-2 top-4 transform -translate-y-1/2 pointer-events-none'>
                          <ArrowDropDown
                            className={`text-xs ${
                              row.unit[0] ? 'text-sky-600' : 'text-white '
                            }`}
                          />
                        </span>
                      </div>
                    </div>
                  </td>
                ) : (
                  <td className='w-[27.21%]'>
                    <div className='flex items-center'>
                      <div className='relative w-1/2'>
                        <input
                          type='number'
                          step='0.01'
                          value={
                            editRowIndex === index ? editRow.value1 : row.value1
                          }
                          onChange={(event) => handleChange(event, 'value1')}
                          className='w-full p-2 rounded-sm mt-1 ms-2 alignment'
                          disabled={editRowIndex !== index}
                        />
                        <div className='absolute right-0 top-0.5'>
                          <select
                            value={
                              editRowIndex === index
                                ? editRow.unit1
                                : row.unit[0]
                            }
                            onChange={(event) => handleChange(event, 'unit1')}
                            className={`cursor-pointer appearance-none px-2 pe-5 py-1 rounded-md leading-tight outline-none ms-1 mt-1.5 font-bold text-xs ${
                              row.unit[0]
                                ? 'text-sky-600 bg-white drop-shadow-md'
                                : 'bg-sky-600 text-white'
                            } w-[66px]`}
                            style={{ width: '66px' }}
                            disabled={editRowIndex !== index}
                          >
                            <option className='text-xs truncate'>
                              {row.unit[0]}
                            </option>
                            {units
                              .filter((unit) => {
                                {/* console.log('row.unitType:', row.unitType);
                                console.log('unit.unit_type:', unit.unit_type); */}
                                return (
                                  unit.unit_type.toLowerCase() ===
                                  row.unitType.toLowerCase()
                                );
                              })
                              .map((unit) => {
                                const unitValues = Object.values(unit.units);
                                if (unitValues.length >= 2) {
                                  const firstArray = unitValues[0];
                                  return firstArray;
                                }
                                return [];
                              })
                              .flat()
                              .map((unitName) => (
                                <option
                                  key={unitName}
                                  className='text-xs truncate'
                                >
                                  {unitName}
                                </option>
                              ))}
                          </select>

                          <span className='absolute right-0 top-[1.15rem] transform -translate-y-1/2 pointer-events-none'>
                            <ArrowDropDown
                              className={`text-xs ${
                                row.unit[0] ? 'text-sky-600' : 'text-white '
                              }`}
                            />
                          </span>
                        </div>
                      </div>

                      <div className='relative w-1/2 '>
                        <input
                          type='number'
                          step='0.01'
                          className='w-full p-2 rounded-sm mt-1 ms-2 alignment'
                          value={
                            editRowIndex === index ? editRow.value2 : row.value2
                          }
                          onChange={(event) => handleChange(event, 'value2')}
                          disabled={editRowIndex !== index}
                        />
                        <div className='absolute right-0 top-0.5'>
                          <select
                            value={
                              editRowIndex === index
                                ? editRow.unit2
                                : row.unit[0]
                            }
                            onChange={(event) => handleChange(event, 'unit2')}
                            className={`cursor-pointer appearance-none  px-2 py-1 rounded-md leading-tight outline-none ms-1 mt-1.5 font-bold text-xs ${
                              row.unit[1]
                                ? 'text-sky-600 bg-white drop-shadow-md'
                                : 'bg-sky-600 text-white'
                            }`}
                            style={{ width: '66px' }}
                            disabled={editRowIndex !== index}
                          >
                            <option className='text-xs'>{row.unit[1]}</option>
                            {units
                              .filter(
                                (unit) =>
                                  unit.unit_type.toLowerCase() ===
                                  row.unitType.toLowerCase()
                              )
                              .map((unit) => {
                                const unitValues = Object.values(unit.units);
                                if (unitValues.length >= 2) {
                                  return unitValues[1]; // Get the second array within units
                                }
                                return [];
                              })
                              .flat()
                              .map((unitName) => (
                                <option key={unitName} className='text-xs'>
                                  {unitName}
                                </option>
                              ))}
                          </select>
                          <span className='absolute right-2 top-4 transform -translate-y-1/2 pointer-events-none'>
                            <ArrowDropDown
                              className={`text-xs ${
                                row.unit[1] ? 'text-sky-600' : 'text-white '
                              }`}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                )}
                <td className='relative w-[8.26%]'>
                  <div className='flex items-center justify-center h-10'>
                    {row.file !== null ? (
                      <div
                        className='flex items-center'
                        onClick={() => handleGreenIconClick(index)}
                      >
                        <FilePresent
                          className='w-6 h-6 mr-1 text-gray-400'
                          style={{ color: 'green' }}
                        />
                        <div className='w-[56px] truncate text-sky-600'>
                          {row.fileName}
                        </div>
                      </div>
                    ) : (
                      <div className='flex items-center'>
                        <FilePresent className='w-6 h-6 text-sky-600 hover:text-gray-700 cursor-pointer' />
                        <div className='w-[56px] truncate text-sky-600'>
                          {row.file !== null ? row.fileName : 'No file'}
                        </div>
                      </div>
                    )}
                  </div>
                  <PdfPreviewModal
                    isOpen={showPreviewModal && previewRowIndex === index}
                    onClose={closeModal}
                    file={row.file}
                    fileName={row.fileName}
                    modifiedTime={row.modifiedTime}
                    row={row}
                    scope={scope}
                    uploadedBy={row.uploadedBy}
                  />
                </td>
                <td className='w-[6.32%]'>
                  <div className='flex ml-2'>
                    <button
                      className='w-[85px] h-[30px] px-2.5 py-1 bg-sky-600 rounded-l flex-col justify-center items-center inline-flex disabled:opacity-70'
                      disabled
                    >
                      <div className='justify-center items-center gap-2 inline-flex'>
                        <div className='relative text-white text-[13px] font-medium leading-snug tracking-wide'>
                          Assign to
                        </div>
                      </div>
                    </button>
                    <button
                      className='flex justify-center items-center w-[25px] h-[30px] px-2.5 py-1 bg-sky-600 rounded-r disabled:opacity-70'
                      disabled
                    >
                      <div className='relative inline-flex'>
                        <div className='absolute -mr-2 inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                          <ArrowDropDown className='text-white bg-blue' />
                        </div>
                        <select
                          value={row.value}
                          className='flex justify-center items-center w-[25px] h-[30px] px-2.5 py-1 bg-sky-600 rounded-r cursor-pointer appearance-none focus:outline-none text-white bg-blue hover:bg-light-blue-300 disabled:opacity-70'
                          disabled
                        >
                          <option value='' className='text-white pe-1'></option>
                          {/* {users?.map(({ username }) => (
                          <option key={username}>{username}</option>
                        ))} */}
                        </select>
                      </div>
                    </button>
                  </div>
                </td>
                <td className='w-[2.32%]'>
                  {editRowIndex === index ? (
                    <SaveOutlined
                      className='cursor-pointer hover:text-stone-400'
                      onClick={saveHandler}
                    />
                  ) : (
                    <MoreVert
                      className='cursor-pointer'
                      onClick={() => toggleRowDropdown(index)}
                    />
                  )}
                </td>
                {showDropdown[index] && (
                  <div
                    ref={dropdownRef}
                    className='absolute bg-white border border-gray-300 rounded shadow w-[80px] right-4 top-2 z-50'
                  >
                    <div
                      className='flex justify-around items-center bg-white cursor-pointer'
                      onClick={() => handleUpdateClick(index, row)}
                    >
                      <EditOutlined className='text-red-600' />
                      Update
                    </div>
                    <hr className='border border-stone-300 mx-0.5 my-1' />
                    <div
                      className='flex justify-around items-center cursor-pointer'
                      onClick={() => handleRowDelete(row.id)}
                    >
                      <DeleteOutline className='text-red-600' />
                      Delete
                    </div>
                  </div>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <ConfirmationModal
          isOpen={isDeleteConfirmationOpen}
          onClose={closeDeleteConfirmation}
          onConfirm={confirmDelete}
          message='Are you sure you want to delete this row?'
        />
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      </>
    )
  );
}

export default ShowEmission;