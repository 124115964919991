import React, { useState } from "react";
import {
  Factory,
  ParkOutlined,
  DeleteOutline,
  Tungsten,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  Water,
  EmojiNature
} from "@mui/icons-material";
import material from "../../assets/images/material.svg"
const Aside = ({ activeTab, handleTabClick }) => {
  const [isEnergySectionVisible, setEnergySectionVisible] = useState(false);
  const [isWasteVisible, setWasteVisible] = useState(false);

  const toggleEnergySectionVisibility = () => {
    setEnergySectionVisible(!isEnergySectionVisible);
    setWasteVisible(false);
  };
  const toggleWasteVisible = () => {
    setWasteVisible(!isWasteVisible);
    setEnergySectionVisible(false);
  };


  return (
    <div className="m-3 ml-2 p-2 border border-r-2 border-b-2 shadow-lg rounded-lg">
      <div className="flex items-start py-4 min-w-[200px] min-h-[85vh] rounded-lg text-[0.875rem]">
        <div className="flex flex-col w-full font-medium">
          <button className="flex items-center px-4 py-2 -mt-4 mb-8 rounded-none focus:outline-none text-[#727272] font-bold">
            <span className="text-[15px]">Environment</span>
          </button>
          <button
            className={`flex items-center justify-between px-2 py-2 mb-2 focus:outline-none w-full ${activeTab === "Emissions"
              ? "text-[#007EEF] border-l-4 border-[#007EEF]"
              : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
              }`}
            onClick={() => handleTabClick("Emissions")}
          >
            <Factory className="w-5 h-5 mr-2" />
            <span className="mr-7">Emissions</span>
            <div className=" inset-y-0 -right-2 flex items-center pointer-events-none">
              <KeyboardArrowDownOutlined
                className="text-neutral-500 "
                style={{ fontSize: "25px" }}
              />
            </div>
          </button>
          <div
            className={`relative`}
          >
            <button
              className={`flex items-center justify-between px-2 py-2 mb-2 focus:outline-none w-full ${activeTab === "Energy consumed inside the organization" ||
                  activeTab === "Energy consumption outside of the organization" ||
                  activeTab === "Energy Intensity" ||
                  activeTab === "Reduction of energy consumption" ||
                  activeTab === "Reductions in energy requirements of products and services"
                  ? "text-[#007EEF]"
                  : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
                }`}
              onClick={toggleEnergySectionVisibility}
            >
              <Tungsten className="w-5 h-5 mr-2" />
              <span className="mr-12">Energy</span>
              <div className="inset-y-0 -right-2 flex items-center pointer-events-none">
                {isEnergySectionVisible ? (
                  <KeyboardArrowUpOutlined
                    className="text-neutral-500"
                    style={{ fontSize: "25px" }}
                  />
                ) : (
                  <KeyboardArrowDownOutlined
                    className="text-neutral-500"
                    style={{ fontSize: "25px" }}
                  />
                )}
              </div>
            </button>

            {/* Energy section content */}
            {isEnergySectionVisible && (
              <>
                <div className="bg-white px-2 ml-5 mt-2 border-l-2 border-gray-300">
                  <div><p className="text-[12px]  ml-3  text-[#727272]">Topic disclosure</p></div>
                  <div><p className={`flex  text-start ml-4 px-2 py-2  focus:outline-none w-full text-[12px] cursor-pointer ${activeTab === "Energy consumed inside the organization"
                    ? "text-sky-700"
                    : "bg-transparent text-[#727272]"
                    }`} onClick={() => handleTabClick("Energy consumed inside the organization")}>Energy consumed inside the organization</p></div>
                  <div><p className={`flex  text-start ml-4 px-2 py-2  focus:outline-none w-full text-[12px] cursor-pointer ${activeTab === "Energy consumption outside of the organization"
                    ? "text-sky-700"
                    : "bg-transparent text-[#727272] "
                    }`} onClick={() => handleTabClick("Energy consumption outside of the organization")}>Energy consumption outside of the organization</p></div>
                  <div><p className={`flex  text-start ml-4 px-2 py-2  focus:outline-none w-full text-[12px] cursor-pointer ${activeTab === "Energy Intensity"
                    ? "text-sky-700"
                    : "bg-transparent text-[#727272] "
                    }`} onClick={() => handleTabClick("Energy Intensity")}>Energy Intensity</p></div>
                  <div><p className={`flex  text-start ml-4 px-2 py-2  focus:outline-none w-full text-[12px] cursor-pointer ${activeTab === "Reduction of energy consumption"
                    ? "text-sky-700"
                    : "bg-transparent text-[#727272] "
                    }`} onClick={() => handleTabClick("Reduction of energy consumption")}>Reduction of energy consumption</p></div>
                  <div><p className={`flex  text-start ml-4 px-2 py-2  focus:outline-none w-full text-[12px] cursor-pointer ${activeTab === "Reductions in energy requirements of products and services"
                    ? "text-sky-700"
                    : "bg-transparent text-[#727272] "
                    }`} onClick={() => handleTabClick("Reductions in energy requirements of products and services")}>Reductions in energy requirements of products and services</p></div>

                </div>
              </>
            )}
          </div>

          <div
            className={`relative `}
          >
            <button
              className={`flex items-center justify-between px-2 py-2 mb-2 focus:outline-none w-full ${activeTab === "Significant waste related impact" ||
                  activeTab === "Management of significant waste related impacts" ||
                  activeTab === "Waste generated" ||
                  activeTab === "Waste Diverted from disposal" ||
                  activeTab === "Waste diverted to disposal"
                  ? "text-sky-700"
                  : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
                }`}
              onClick={toggleWasteVisible}
            >

              <DeleteOutline className="w-5 h-5 mr-2" />
              <span className="mr-12">Waste</span>
              <div className="inset-y-0 -right-2 flex items-center pointer-events-none">
                {isWasteVisible ? (
                  <KeyboardArrowUpOutlined
                    className="text-neutral-500"
                    style={{ fontSize: "25px" }}
                  />
                ) : (
                  <KeyboardArrowDownOutlined
                    className="text-neutral-500"
                    style={{ fontSize: "25px" }}
                  />
                )}
              </div>
            </button>

            {/* Energy section content */}
            {isWasteVisible && (
              <>
                <div className="bg-white px-2 ml-5 mt-2 border-l-2 border-gray-300">
                  <div><p className="text-[12px]  ml-3  text-gray-400">Topic Management Disclosure</p></div>
                  <div>
                    <p className={`flex  text-start ml-4 px-2 py-2  focus:outline-none w-full text-[12px] cursor-pointer ${activeTab === "Significant waste related impact"
                      ? "text-sky-700"
                      : "bg-transparent text-[#727272] "
                      }`} onClick={() => handleTabClick("Significant waste related impact")}>Significant waste related impact</p>
                  </div>
                  <div>
                    <p className={`flex  text-start ml-4 px-2 py-2  focus:outline-none w-full text-[12px] cursor-pointer ${activeTab === "Management of significant waste related impacts"
                      ? "text-sky-700"
                      : "bg-transparent text-[#727272] "
                      }`} onClick={() => handleTabClick("Management of significant waste related impacts")}>Management of significant waste related impacts</p>
                  </div>
                  <div><p className="text-[12px]  ml-3  text-gray-400">Topic Disclosure</p></div>

                  <div>
                    <p className={`flex  text-start ml-4 px-2 py-2  focus:outline-none w-full text-[12px] cursor-pointer ${activeTab === "Waste generated"
                      ? "text-sky-700"
                      : "bg-transparent text-[#727272]"
                      }`} onClick={() => handleTabClick("Waste generated")}>Waste generated</p>
                  </div>

                  <div>
                    <p className={`flex  text-start ml-4 px-2 py-2  focus:outline-none w-full text-[12px] cursor-pointer ${activeTab === "Waste Diverted from disposal"
                      ? "text-sky-700"
                      : "bg-transparent text-[#727272] "
                      }`} onClick={() => handleTabClick("Waste Diverted from disposal")}>Waste Diverted from disposal</p>
                  </div>
                  <div>
                    <p className={`flex  text-start ml-4 px-2 py-2  focus:outline-none w-full text-[12px] cursor-pointer ${activeTab === "Waste diverted to disposal"
                      ? "text-sky-700"
                      : "bg-transparent text-[#727272] "
                      }`} onClick={() => handleTabClick("Waste diverted to disposal")}>Waste diverted to disposal</p></div>

                </div>
              </>
            )}
          </div>
          <button
            className={`flex items-center justify-between px-2 py-2 mb-2 focus:outline-none ${activeTab === "Materials"
              ? "text-[#007EEF] border-l-4 border-[#007EEF]"
              : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
              }`}
            onClick={() => handleTabClick("Materials")}
          >
            <img src={material} className="w-5 h-5 mr-2" />
            <span className="mr-8">Materials</span>
            <div className=" inset-y-0 -right-2 flex items-center pointer-events-none">
              <KeyboardArrowDownOutlined
                className="text-neutral-500"
                style={{ fontSize: "25px" }}
              />
            </div>
          </button>
          <button
            className={`flex items-center justify-between px-2 py-2 mb-2 focus:outline-none ${activeTab === "Water and effluents"
              ? "text-[#007EEF] border-l-4 border-[#007EEF]"
              : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
              }`}
            onClick={() => handleTabClick("Water and effluents")}
          >
            <Water className="w-5 h-5 mr-2" />
            <span className="mr-8">Water and effluents</span>
            <div className=" inset-y-0 -right-2 flex items-center pointer-events-none">
              <KeyboardArrowDownOutlined
                className="text-neutral-500"
                style={{ fontSize: "25px" }}
              />
            </div>
          </button>
          <button
            className={`flex items-center justify-between  px-2 py-2 mb-2 focus:outline-none ${activeTab === "Bio diversity"
              ? "text-[#007EEF] border-l-4 border-[#007EEF]"
              : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
              }`}
            onClick={() => handleTabClick("Bio diversity")}
          >
            <EmojiNature className="w-5 h-5 mr-2" />
            <span className="mr-4">Bio diversity</span>
            <div className=" inset-y-0 -right-2 flex items-center pointer-events-none">
              <KeyboardArrowDownOutlined
                className="text-neutral-500"
                style={{ fontSize: "25px" }}
              />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Aside;
