import React, { useEffect, useState, useRef } from "react";
import { AddOutlined, EditOutlined } from "@mui/icons-material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const Preferences = () => {
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState(false);
  const [loopen, setLoOpen] = useState(false);
  const [framework, setFramework] = useState(false);
  const [regulations, setRegulations] = useState(false);
  const [targets, setTargets] = useState(false);
  const [certifications, setCertifications] = useState(false);
  const [ratings, srtRatings] = useState(false);
  const isMounted = useRef(true);
  const handleEditToggle = () => {
    setEdit((prevEdit) => !prevEdit);
  };
  const LoaderOpen = () => {
    setLoOpen(true);
  };
  const LoaderClose = () => {
    setLoOpen(false);
  };
  const fetchpreference = async () => {
    LoaderOpen();
    const stringWithQuotes = localStorage.getItem("accessToken");
    const stringWithoutQuotes = stringWithQuotes.replace(/"/g, "");
    const options = {
      headers: {
        Authorization: `Bearer ${stringWithoutQuotes}`,
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/organization_preference/`,
      options
    ).then((response) => {
      setData(response.data.org_data.sdg);
      setFramework(response.data.org_data.framework);
      setRegulations(response.data.org_data.regulation);
      setTargets(response.data.org_data.target);
      setCertifications(response.data.org_data.certification);
      srtRatings(response.data.org_data.rating);
      LoaderClose();
    })
    .catch((error) => {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Oops, something went wrong";
      toast.error(errorMessage, {
        // Corrected 'error.message'
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      LoaderClose();

    });
    // console.log(response.data, "morganization_preference");
  };
  useEffect(() => {
    if (isMounted.current) {
      fetchpreference();
      isMounted.current = false;
    }
    return () => {
      isMounted.current = false;
    };
  }, []);
  return (
    <>
     <ToastContainer style={{ fontSize: "12px" }} />
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="text-neutral-800 text-[15px] font-bold leading-tight">
          Preferences
        </div>
        <div>
          <div className="text-sky-600 text-[10px] font-bold leading-[13px] space-x-2">
            <button
              onClick={handleEditToggle}
              className="text-sky-600 text-[10px] font-normal leading-[13px] space-x-2 me-2"
            >
              <EditOutlined style={{ fontSize: "18px" }} />
              <span>Edit</span>
            </button>
          </div>
        </div>
      </div>
      <h3 className="text-neutral-800 text-[13px] font-bold leading-none mt-8">
        Frameworks
      </h3>
      <div className="w-full h-[0px] border border-gray-200 mt-2 mb-4"></div>

      <div className="grid grid-cols-4 gap-2 mb-3">
        {framework &&
          framework.map((item) => (
            <div className="relative shadow-sm h-20  border border-gray-200">
              <div className="flex justify-center h-10  mb-2">
                <img
                  src={item.Image}
                  alt="cover"
                  className="transition-all w-[80%] aspect-[3/2] object-contain mt-2"
                />
              </div>
              <div className="h-10">
                <p className="text-[12px] text-center">{item.name}</p>
              </div>
            </div>
          ))}
        {edit && (
          <Link to="../home/dashboard/preferences?currentPage=2">
            <div className="w-[70px] h-[70px]  rounded-lg border border-dashed border-neutral-400 flex justify-center items-center cursor-pointer">
              <AddOutlined />
            </div>
          </Link>
        )}
      </div>
      <h3 className="text-neutral-800 text-[13px] font-bold leading-none mt-8">
        Regulations
      </h3>
      <div className="w-full h-[0px] border border-gray-200 mt-2 mb-4"></div>
      <div className="grid grid-cols-4 gap-2 mb-3">
        {regulations &&
          regulations.map((item) => (
            <div className="relative shadow-sm h-20  border border-gray-200">
              <div className="flex justify-center h-10  mb-2">
                <img
                  src={item.Image}
                  alt="cover"
                  className="transition-all w-[80%] aspect-[3/2] object-contain mt-2"
                />
              </div>
              <div className="h-10">
                <p className="text-[12px] text-center">{item.name}</p>
              </div>
            </div>
          ))}

        {edit && (
          <Link to="../home/dashboard/preferences?currentPage=6">
            <div className="w-[70px] h-[70px] rounded-lg border border-dashed border-neutral-400 flex justify-center items-center cursor-pointer">
              <AddOutlined />
            </div>
          </Link>
        )}
      </div>
      <h3 className="text-neutral-800 text-[13px] font-bold leading-none mt-8">
        Targets
      </h3>
      <div className="w-full  border border-gray-200 mt-2 mb-4"></div>
      <div className="grid grid-cols-4 gap-2 mb-3">
        {targets &&
          targets.map((item) => (
            <div className="relative shadow-sm h-20  border border-gray-200">
              <div className="flex justify-center h-10  mb-2">
                <img
                  src={item.Image}
                  alt="cover"
                  className="transition-all w-[80%] aspect-[3/2] object-contain mt-2"
                />
              </div>
              <div className="h-10">
                <p className="text-[12px] text-center">{item.name}</p>
              </div>
            </div>
          ))}

        {edit && (
          <Link to="../home/dashboard/preferences?currentPage=4">
            <div className="w-[70px] h-[70px] rounded-lg border border-dashed border-neutral-400 flex justify-center items-center cursor-pointer">
              <AddOutlined />
            </div>
          </Link>
        )}
      </div>
      <h3 className="text-neutral-800 text-[13px] font-bold leading-none mt-8">
        SDG’s
      </h3>
      <div className="w-full h-[0px] border border-gray-200 mt-2 mb-4"></div>
      <div className="grid grid-cols-8 gap-2 mb-3">
        {data &&
          data.map((item) => (
            <img src={item.Image} alt="cover" className="transition-all" />
          ))}
        {edit && (
          <Link to="../home/dashboard/preferences?currentPage=1">
            <div className="w-[63px] h-[63px] rounded-lg border border-dashed border-neutral-400 flex justify-center items-center cursor-pointer">
              <AddOutlined />
            </div>
          </Link>
        )}
      </div>
      <h3 className="text-neutral-800 text-[13px] font-bold leading-none mt-8">
        Certifications
      </h3>
      <div className="w-full h-[0px] border border-gray-200 mt-2 mb-4"></div>
      <div className="grid grid-cols-4 gap-2 mb-3">
        {certifications &&
          certifications.map((item) => (
            <div className="relative shadow-sm h-20  border border-gray-200">
              <div className="flex justify-center h-10  mb-2">
                <img
                  src={item.Image}
                  alt="cover"
                  className="transition-all w-[80%] aspect-[3/2] object-contain mt-2"
                />
              </div>
              <div className="h-10">
                <p className="text-[12px] text-center">{item.name}</p>
              </div>
            </div>
          ))}
        {edit && (
          <Link to="../home/dashboard/preferences?currentPage=3">
            <div className="w-[70px] h-[70px] rounded-lg border border-dashed border-neutral-400 flex justify-center items-center cursor-pointer ">
              <AddOutlined />
            </div>
          </Link>
        )}
      </div>
      <h3 className="text-neutral-800 text-[13px] font-bold leading-none mt-8">
        Ratings
      </h3>
      <div className="w-full  border border-gray-200 mt-2 mb-4"></div>
      <div className="grid grid-cols-4 gap-2 mb-3">
        {ratings &&
          ratings.map((item) => (
            <div className="relative shadow-sm h-20  border border-gray-200">
              <div className="flex justify-center h-10  mb-2">
                <img
                  src={item.Image}
                  alt="cover"
                  className="transition-all w-[80%] aspect-[3/2] object-contain mt-2"
                />
              </div>
              <div className="h-10">
                <p className="text-[12px] text-center">{item.name}</p>
              </div>
            </div>
          ))}
        {edit && (
          <Link to="../home/dashboard/preferences?currentPage=5">
            <div className="w-[70px] h-[70px] rounded-lg border border-dashed border-neutral-400 flex justify-center items-center cursor-pointer">
              <AddOutlined />
            </div>
          </Link>
        )}
      </div>
    </div>
     <Backdrop
     sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={loopen}
   >
     <CircularProgress color="inherit" />
   </Backdrop>
   </>
  );
};

export default Preferences;
