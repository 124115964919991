import industryList from 'components/data/sector';
import { Country, State, City } from 'country-state-city';
import BusinessActivities from './Organization/BusinessActivities';
import BusinessRelationships from './Organization/BusinessRelationships';
import { ArrowBackIosNewOutlined } from '@mui/icons-material';
import axios from 'axios';
import React, { useState, useEffect, useMemo } from 'react';
import { timeZones } from 'components/data/timezones';
import { useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';

const dateFormatOptions = [
  { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
  { label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
  { label: 'YYYY/MM/DD', value: 'YYYY/MM/DD' },
];

const currencyOptions = [
  { label: 'USD', value: 'USD' },
  { label: 'EUR', value: 'EUR' },
  { label: 'GBP', value: 'GBP' },
  { label: 'CAD', value: 'CAD' },
  { label: 'AUD', value: 'AUD' },
  { label: 'INR', value: 'INR' },
  { label: 'SGD', value: 'SGD' },
  { label: 'KRW', value: 'KRW' },
  { label: 'JPY', value: 'JPY' },
  { label: 'TTD', value: 'TTD' },
];

const reportFramework = ['GRI'];

const initialState = {
  generalDetails: {
    name: '',
    email: '',
    phone: '',
    website: '',
    typelocation: '',
    location: '',
    Empcount: '',
    revenue: '',
    mobile: '',
    fax: '',
    sector: '',
    subIndustry: '',
    corporateEntity: '',
    timeZone: '',
    language: '',
    dateFormat: ''
  },
  addressInformation: {
    country: '',
    state: '',
    city: '',
    street: '',
    zipCode: '',
    latitude: '',
    longitude: '',
  },
  reportingPeriodInformation: {
    fromDate: '',
    toDate: '',
    reportingFramework: '',
  },
  errors: {
    latitude: '',
    longitude: '',
  },
};

const handleBusinessActivitiesSubmit = (event) => {
  event.preventDefault();
  // logic for submitting general details section
};

const Location = ({ heading }) => {
  const [formData, setFormData] = useState(initialState);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [subIndustries, setSubIndustries] = useState([]);

  const selectedIndustryData = industryList.find(
    (industry) => industry.id === selectedIndustry
  );
  const subIndustriesForSelectedIndustry = selectedIndustryData
    ? selectedIndustryData.subIndustries
    : [];

  const handleSectorChange = (event) => {
    const selectedSector = event.target.value;
    setSelectedIndustry(selectedSector);

    const selectedIndustryObj = industryList.find(
      (industry) => industry.industry === selectedSector
    );

    const subIndustriesForSector = selectedIndustryObj?.subIndustries || [];

    setSubIndustries(subIndustriesForSector);
    handleGeneralDetailsChange(event);

    console.log('Sub Industries Found:', subIndustriesForSector);
  };

  const handleGeneralDetailsChange = (event) => {
    const { name, value } = event.target;
    // Check if the 'name' field contains an '&'
    if (name === 'name' && value.includes('&')) {
      alert('The name field should not contain &');
      return; // Stop the function if '&' is found in the 'name' field
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      generalDetails: {
        ...prevFormData.generalDetails,
        [name]: value,
      },
    }));
  };

  const handleAddressInformationChange = (event) => {
    const { name, value } = event.target;

    const latLongRegex = /^-?\d+\.\d{0,6}$/;

    // Check if the field is latitude or longitude and validate the format
    if (
      (name === 'latitude' || name === 'longitude') &&
      !latLongRegex.test(value)
    ) {
      setFormData((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [name]: `${name} must be in the decimal format eg. 77.001012`,
        },
        addressInformation: {
          ...prevState.addressInformation,
          [name]: value,
        },
      }));
      return; // Exit the function if validation fails
    } else {
      setFormData((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [name]: '', // Clear error message if validation passes
        },
        addressInformation: {
          ...prevState.addressInformation,
          [name]: value,
        },
      }));
    }

    // Check if the name of the input field is 'country' or 'state'
    if (name === 'country') {
      handleCountryChange({ target: { value } });
    }
    if (name === 'state') {
      handleStateChange({ target: { value } });
    }
  };

  const handleReportingPeriodChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      reportingPeriodInformation: {
        ...prevState.reportingPeriodInformation,
        [name]: value,
      },
    }));
  };


  const [selectedTimezone, setSelectedTimezone] = useState(
    formData.generalDetails.timezone
  );

  const handleTimezoneChange = (event) => {
    const value = event.target.value;
    setSelectedTimezone(value);
  };

  const [languages, setLanguages] = useState([]);

  const [selectedFrameworks, setSelectedFrameworks] = useState([]);

  function handleFrameworkChange(event) {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedFrameworks([...selectedFrameworks, value]);
    } else {
      setSelectedFrameworks(
        selectedFrameworks.filter((framework) => framework !== value)
      );
    }
  }

  // country, states, cities

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  }, []);

  const handleCountryChange = (event) => {
    const countryId = event.target.value;
    setSelectedCountry(countryId);

    const statesOfSelectedCountry = State.getStatesOfCountry(countryId);
    setStates(statesOfSelectedCountry);
  };

  const handleStateChange = (event) => {
    const stateId = event.target.value;
    setSelectedState(stateId);

    const citiesOfSelectedState = City.getCitiesOfState(
      selectedCountry,
      stateId
    );
    setCities(citiesOfSelectedState);
    console.log(selectedCountry, event.target.value, citiesOfSelectedState);
  };

  const handleCityChange = (event) => {
    const cityId = event.target.value;
    setSelectedCity(cityId);
  };

  // handle add location

  const token = useSelector((state) => state.auth.authToken);

  const handleAddLocation = async (event, data) => {
    event.preventDefault();

    const url = `${process.env.REACT_APP_BACKEND_URL}/locationonlyview`;

    const payload = {
      // corporateentity_id:1,
      corporateentity_id: data.generalDetails.corporateEntity || null,
      name: data.generalDetails.name || 'Location 1',
      typelocation: data.generalDetails.typelocation || 'Headquarter Location',
      currency: data.generalDetails.currency || 'US Dollars',
      dateformat: data.generalDetails.dateFormat || 'mm/dd/yy',
      phone: data.generalDetails.phone || 9876543210,
      mobile: data.generalDetails.mobile || 9876543210,
      website: data.generalDetails.website || 'https://www.sustainext.ai',
      fax: data.generalDetails.fax || 234567,
      sector: data.generalDetails.sector || 'Default',
      location_type: data.generalDetails.typelocation || 'Default',
      sub_industry: data.generalDetails.subIndustry || 'Default',
      timezone: data.generalDetails.timeZone || '+0:00',
      latitude: data.addressInformation.latitude || null,
      longitude: data.addressInformation.longitude || null,
      employeecount: data.generalDetails.Empcount || 100,
      language: data.generalDetails.language || 'English',
      revenue: data.generalDetails.revenue || 100000,
      currency: data.generalDetails.currency || 'US dollars',
      streetaddress: data.addressInformation.street || '1st btm',
      area: 56775,
      type_of_services: 'sfsdfsd',
      type_of_product: 'adsf',
      type_of_business_activities: 'jhghf',
      zipcode: data.addressInformation.zipCode || null,
      state: data.addressInformation.state || 'Karnataka',
      city: data.addressInformation.city || 'Bengaluru',
      country: data.addressInformation.country || 'IN',
      from_date: data.reportingPeriodInformation.fromDate || null,
      to_date: data.reportingPeriodInformation.toDate || null,
      // framework: data.reportingPeriodInformation.reportingFramework || 'GRI',
    };

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken.replace(/"/g, ''),
    };

    try {
      const response = await axios.post(url, payload, {
        headers: headers,
      });
      navigate('../../general/structure');
      console.log('POST request successful:', response.data);
    } catch (error) {
      console.error('Error:', error);
    }

    console.log(payload, 'payload');
  };

  const [corporates, setCorporates] = useState([]);

  const accessToken = localStorage.getItem('accessToken');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken.replace(/"/g, ''),
    };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/corporategetonly`,
          { headers }
        );
        console.log('Corporates:', response.data);
        setCorporates(response.data);
      } catch (e) {
        console.log(
          'failed fetching organization',
          process.env.REACT_APP_BACKEND_URL,
          headers
        );
      }
    };

    fetchData();
  }, [heading]);

  const corporatesData = useMemo(() => corporates, [corporates]);

  const frameworkMapping = {
    1: 'GRI',
    2: 'CDP',
    3: 'SASB',
    4: 'TCFD',
    5: 'BRSR',
    6: 'UN PRI',
    // ... other mappings
  };

  // extract data from location

  const [editData, setEditData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const dataParam = params.get('data');

    if (dataParam) {
      const decodedData = decodeURIComponent(dataParam);
      try {
        const parsedData = JSON.parse(decodedData);
        setEditData(parsedData);
        console.log('Parsed location data:', parsedData);
      } catch (error) {
        console.error('Error parsing data:', error);
      }
    }
  }, [location]);


  const handleEditLocation = async (event, data, id) => {
    event.preventDefault();

    const url = `${process.env.REACT_APP_BACKEND_URL}/location/${id}/`;

    const payload = {
      // corporateentity: data.generalDetails.corporateEntity || 'CORPORATE21',
      name: data.generalDetails.name || '',
      typelocation: data.generalDetails.typelocation || '',
      currency: data.generalDetails.currency || '',
      dateformat: data.generalDetails.dateFormat || '',
      phone: data.generalDetails.phone || null,
      mobile: data.generalDetails.mobile || null,
      website: data.generalDetails.website || '',
      fax: data.generalDetails.fax || 234567,
      sector: data.generalDetails.sector || '',
      location_type: data.generalDetails.typelocation || '',
      sub_industry: data.generalDetails.subIndustry || '',
      timezone: data.generalDetails.timeZone || '',
      employeecount: data.generalDetails.Empcount || null,
      language: data.generalDetails.language || '',
      revenue: data.generalDetails.revenue || null,
      currency: data.generalDetails.currency || '',
      streetaddress: data.addressInformation.street || '',
      area: null,
      type_of_services: 'sfsdfsd',
      type_of_product: 'adsf',
      type_of_business_activities: 'jhghf',
      zipcode: data.addressInformation.zipCode || null,
      state: data.addressInformation.state || '',
      city: data.addressInformation.city || '',
      country: data.addressInformation.country || '',
      latitude: data.addressInformation.latitude || null,
      longitude: data.addressInformation.longitude || null,
      from_date: data.reportingPeriodInformation.fromDate || null,
      to_date: data.reportingPeriodInformation.toDate || null,
      framework: data.reportingPeriodInformation.reportingFramework || 'GRI',
    };

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken.replace(/"/g, ''),
    };

    try {
      const response = await axios.put(url, payload, {
        headers: headers,
      });
      navigate('../general/structure');
      alert('Location updated successfully');
    } catch (error) {
      console.error('Error:', error);
    }

    console.log(payload, 'payload');
  };

  useEffect(() => {
    if (editData) {
      const selectedCountryCode = editData.filteredData[0].country;
      const selectedStateCode = editData.filteredData[0].state;

      const statesOfSelectedCountry =
        State.getStatesOfCountry(selectedCountryCode);
      const citiesOfSelectedState = City.getCitiesOfState(
        selectedCountryCode,
        selectedStateCode
      );

      const selectedIndustryValue = editData.filteredData[0].sector;

      // Find the corresponding industry object
      const selectedIndustryData = industryList.find(
        (industry) => industry.industry === selectedIndustryValue
      );

      // Get the sub-industries for the selected industry
      const subIndustriesForSelectedIndustry =
        selectedIndustryData?.subIndustries || [];

      const frameworkNumbers = editData.filteredData[0].framework; // This might be a number or an array of numbers

      // Map the numeric values to framework names
      const selectedFrameworks = Array.isArray(frameworkNumbers)
        ? frameworkNumbers.map((num) => frameworkMapping[num])
        : [frameworkMapping[frameworkNumbers]];
      console.log('data in lcoation', editData.filteredData[0]);

      setFormData({
        generalDetails: {
          name: editData.filteredData[0].name,
          email: editData.filteredData[0].email,
          phone: editData.filteredData[0].phone,
          website: editData.filteredData[0].website,
          typelocation: editData.filteredData[0].location_type,
          ownership: editData.filteredData[0].owner,
          location: editData.filteredData[0].location_of_headquarters,
          Empcount: editData.filteredData[0].employeecount,
          revenue: editData.filteredData[0].revenue,
          mobile: editData.filteredData[0].mobile,
          fax: editData.filteredData[0].fax,
          sector: editData.filteredData[0].sector,
          subIndustry: editData.filteredData[0].subindustry,
          organisation: editData.filteredData[0].organisation,
          dateFormat: editData.filteredData[0].date_format,
          currency: editData.filteredData[0].currency,
          timeZone: editData.filteredData[0].timezone,
          language: editData.filteredData[0].language,
        },

        addressInformation: {
          country: editData.filteredData[0].country,
          state: editData.filteredData[0].state,
          city: editData.filteredData[0].city,
          street: editData.filteredData[0].streetaddress,
          zipCode: editData.filteredData[0].zipCode,
          latitude: editData.filteredData[0].latitude,
          longitude: editData.filteredData[0].longitude,
        },

        reportingPeriodInformation: {
          fromDate: editData.filteredData[0].from_date,
          toDate: editData.filteredData[0].to_date,
          reportingFramework: editData.filteredData[0].framework,
        },
        errors: {
          latitude: '',
          longitude: '',
        },
      });

      setStates(statesOfSelectedCountry);
      setCities(citiesOfSelectedState);
      setSelectedCountry(selectedCountryCode);
      setSelectedState(selectedStateCode);
      setSelectedCity(editData.filteredData[0].city);

      setSelectedIndustry(selectedIndustryValue);
      setSubIndustries(subIndustriesForSelectedIndustry);

      setSelectedFrameworks(selectedFrameworks);
    }
  }, [editData]);

  useEffect(() => {
    setSelectedTimezone(formData.generalDetails.timeZone);
  }, [formData.generalDetails.timeZone]);

  // Autofill address details

  const [isSameAsCorporate, setIsSameAsCorporate] = useState(false);

  const [selectedCorporateEntityDetails, setSelectedCorporateEntityDetails] = useState(null);

  // Function to handle changes in the corporate entity selection
  const handleCorporateEntityChange = (event) => {
    const selectedCorporateEntityName = event.target.value;
    handleGeneralDetailsChange(event);

    // Find and set the details of the selected corporate entity
    const corporateEntityDetails = corporates.find(
      (corp) => corp.name === selectedCorporateEntityName
    );
    setSelectedCorporateEntityDetails(corporateEntityDetails || null);
  };

  const handleSameAsCorporateChange = (e) => {
    setIsSameAsCorporate(e.target.checked);
    if (e.target.checked && !formData.generalDetails.corporateEntity) {
      // Reset the checkbox to unchecked
      setIsSameAsCorporate(false);
  
      // Show a prompt to select a corporate entity
      alert("Please select a corporate entity first.");
      return; 
    }
    if (e.target.checked && selectedCorporateEntityDetails) {
      const selectedCountryCode = selectedCorporateEntityDetails.Country;
      const selectedStateCode = selectedCorporateEntityDetails.state;
  
      // Load states and cities based on the selected country and state
      const statesOfSelectedCountry = State.getStatesOfCountry(selectedCountryCode);
      const citiesOfSelectedState = City.getCitiesOfState(selectedCountryCode, selectedStateCode);
  
      // Populate the address fields with selected corporate entity details
      setFormData((prevFormData) => ({
        ...prevFormData,
        addressInformation: {
          ...prevFormData.addressInformation,
          street: selectedCorporateEntityDetails.address,
          country: selectedCorporateEntityDetails.Country,
          state: selectedCorporateEntityDetails.state,
          city: selectedCorporateEntityDetails.city,
          zipCode: selectedCorporateEntityDetails.zipcode,
          latitude: selectedCorporateEntityDetails.latitude,
          longitude: selectedCorporateEntityDetails.longitude,
        },
      }));
  
      setStates(statesOfSelectedCountry);
      setCities(citiesOfSelectedState);
  
      // Update the selected country, state, and city
      setSelectedCountry(selectedCountryCode);
      setSelectedState(selectedStateCode);
      setSelectedCity(selectedCorporateEntityDetails.city);
    } else if (!e.target.checked) {
      // Reset the address information if checkbox is unchecked
      setFormData((prevFormData) => ({
        ...prevFormData,
        addressInformation: {
          ...prevFormData.addressInformation,
          street: '',
          country: '',
          state: '',
          city: '',
          zipCode: '',
          latitude: '',
          longitude: '',
        },
      }));
  
      // Reset states and cities
      setStates([]);
      setCities([]);
  
      // Reset selected country, state, and city
      setSelectedCountry('');
      setSelectedState('');
      setSelectedCity('');
    }
  };
  

  return (
    <div className='px-4 mt-4'>
      <div className='flex justify-between items-center drop-shadow-lg border-b-2 py-6 w-full'>
        <h2 className='self-stretch text-black text-opacity-90 text-[22px] font-normal leading-relaxed flex space-x-8 items-center'>
          <Link to={'../../general/structure'}>
            <ArrowBackIosNewOutlined />
          </Link>
          <span>{editData ? 'Edit Location' : 'Location of Operation'}</span>
        </h2>
        <button
          className='w-[73px] h-[31px] px-[22px] py-2 bg-sky-600 rounded shadow flex-col justify-center items-center inline-flex me-8 cursor-pointer'
          onClick={
            editData
              ? (e) =>
                  handleEditLocation(e, formData, editData.filteredData[0].id)
              : (e) => handleAddLocation(e, formData)
          }

        >
          <div className='text-white text-xs font-bold leading-[15px]'>
            Save
          </div>
        </button>
      </div>
      <div className='space-y-4 bg-white py-4'>
        <div className='grid grid-cols-3 gap-5'>
          <div></div>
          <div></div>
          {editData ? (
            ''
          ) : (
            <div>
              <label
                htmlFor='name'
                className='text-neutral-800 text-[13px] font-normal'
              >
                Corporate Entity
              </label>
              <select
                name='corporateEntity'
                value={formData.generalDetails.corporateEntity}
                onChange={handleCorporateEntityChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              >
                <option value=''>Select Corporate Entity</option>
                {corporates?.map((corp) => (
                  <option key={corp.id} value={corp.id}>
                    {corp.name}
                  </option>
                ))}{' '}
              </select>
            </div>
          )}
        </div>
        <div>
          <h3 className='text-neutral-400 text-xs font-semibold uppercase leading-relaxed tracking-wide my-8 mb-4'>
            General Information
          </h3>
          <div className='grid grid-cols-3 gap-5'>
            <div className='space-y-3'>
              <label
                htmlFor='name'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Name
              </label>
              <input
                type='text'
                name='name'
                value={formData.generalDetails.name}
                onChange={handleGeneralDetailsChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              />
            </div>
            <div></div>
            <div></div>
            <div className='space-y-3'>
              <label
                htmlFor='phone'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Phone
              </label>
              <input
                type='text'
                name='phone'
                value={formData.generalDetails.phone}
                onChange={handleGeneralDetailsChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              />
            </div>

            <div className='space-y-3'>
              <label
                htmlFor='mobile'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Mobile
              </label>
              <input
                type='text'
                name='mobile'
                value={formData.generalDetails.mobile}
                onChange={handleGeneralDetailsChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              />
            </div>
            <div className='space-y-3'>
              <label
                htmlFor='website'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Website
              </label>
              <input
                type='text'
                name='website'
                value={formData.generalDetails.website}
                onChange={handleGeneralDetailsChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              />
            </div>
            <div className='space-y-3'>
              <label
                htmlFor='fax'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Fax
              </label>
              <input
                type='text'
                name='fax'
                value={formData.generalDetails.fax}
                onChange={handleGeneralDetailsChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              />
            </div>
            <div className='space-y-3'>
              <label
                htmlFor='Empcount'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Employee Count
              </label>
              <input
                type='text'
                name='Empcount'
                value={formData.generalDetails.Empcount}
                onChange={handleGeneralDetailsChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              />
            </div>
            <div className='space-y-3'>
              <label
                htmlFor='revenue'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Revenue
              </label>
              <input
                type='text'
                name='revenue'
                value={formData.generalDetails.revenue}
                onChange={handleGeneralDetailsChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              />
            </div>
            <div className='space-y-3'>
              <label
                htmlFor='sector'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Sector
              </label>
              <select
                name='sector'
                value={formData.generalDetails.sector}
                onChange={handleSectorChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              >
                <option value=''>Select Sector</option>
                {industryList.map((industry) => (
                  <option key={industry.id} value={industry.value}>
                    {industry.industry}
                  </option>
                ))}
              </select>
            </div>
            <div className='space-y-3'>
              <label
                htmlFor='subIndustry'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Sub Industry
              </label>
              <select
                name='subIndustry'
                value={formData.generalDetails.subIndustry}
                onChange={handleGeneralDetailsChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              >
                <option value=''>Select Sub Industry</option>
                {subIndustries.map((subIndustry) => (
                  <option key={subIndustry.id} value={subIndustry.value}>
                    {subIndustry.subIndustry}
                  </option>
                ))}
              </select>
            </div>
            <div></div>
            <div className='space-y-3'>
              <label
                htmlFor='typeOfLocation'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Type of Location
              </label>
              <input
                type='text'
                name='typelocation'
                value={formData.generalDetails.typelocation}
                onChange={handleGeneralDetailsChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              />
            </div>
          </div>
          <h3 className='text-lg font-semibold mb-6 mt-[4rem] text-gray-700'>
            ADDRESS INFORMATION
          </h3>

          <div className='grid grid-cols-3 gap-5'>
            <div className='space-y-3'>
              <label
                htmlFor='address'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Street Address
              </label>
              <input
                type='text'
                name='street'
                value={formData.addressInformation.street}
                onChange={handleAddressInformationChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              />
            </div>
            <div></div>
            <div></div>
            <div className='space-y-3'>
              <label
                htmlFor='country'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Country
              </label>

              <select
                name='country'
                value={formData.addressInformation.country}
                onChange={handleAddressInformationChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              >
                <option value=''>Select Country</option>
                {countries.map((country) => (
                  <option key={country.isoCode} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='space-y-3'>
              <label
                htmlFor='state'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                State
              </label>

              <select
                name='state'
                value={formData.addressInformation.state}
                onChange={handleAddressInformationChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              >
                <option value=''>Select State</option>
                {states.map((state) => (
                  <option key={state.isoCode} value={state.isoCode}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='space-y-3'>
              <label
                htmlFor='city'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                City
              </label>

              <select
                name='city'
                value={formData.addressInformation.city}
                onChange={handleAddressInformationChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              >
                <option value=''>Select City</option>
                {cities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>

            <div className='space-y-3'>
              <label
                htmlFor='zipCode'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Zip Code
              </label>
              <input
                type='text'
                name='zipCode'
                value={formData.addressInformation.zipCode}
                onChange={handleAddressInformationChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              />
            </div>
            <div className='space-y-3'>
              <label
                htmlFor='latitude'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Latitude
              </label>
              <input
                type='number'
                name='latitude'
                value={formData.addressInformation.latitude}
                onChange={handleAddressInformationChange}
                className={`border rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight ${
                  formData.errors.latitude
                    ? 'border-red-500'
                    : 'border-gray-300'
                }`}
              />
              {formData.errors.latitude && (
                <p className='text-red-500 text-xs mt-1'>
                  {formData.errors.latitude}
                </p>
              )}
            </div>
            <div className='space-y-3'>
              <label
                htmlFor='longitude'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Longitude
              </label>
              <input
                type='number'
                name='longitude'
                value={formData.addressInformation.longitude}
                onChange={handleAddressInformationChange}
                className={`border rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight ${
                  formData.errors.longitude
                    ? 'border-red-500'
                    : 'border-gray-300'
                }`}
              />
              {formData.errors.longitude && (
                <p className='text-red-500 text-xs mt-1'>
                  {formData.errors.longitude}
                </p>
              )}
            </div>
            <div className='space-y-3 col-span-3'>
          <label
            htmlFor='sameAsCorporate'
            className='block text-neutral-800 text-[13px] font-normal'
          >
            <input
              type='checkbox'
              name='sameAsCorporate'
              checked={isSameAsCorporate}
              onChange={handleSameAsCorporateChange}
              className='me-2'
            />
            Same as Corporate Entity
          </label>
        </div>
          </div>
          <h3 className='text-lg font-semibold mb-6 mt-[4rem] text-gray-700'>
            LOCALE INFORMATION
          </h3>
          <div className='grid grid-cols-3 gap-5'>
            <div className='space-y-3'>
              <label
                htmlFor='dateFormat'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Date Format
              </label>
              <select
                name='dateFormat'
                value={formData.generalDetails.dateFormat}
                onChange={handleGeneralDetailsChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              >
                {dateFormatOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className='space-y-3'>
              <label
                htmlFor='currency'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Currency
              </label>
              <select
                name='currency'
                value={formData.generalDetails.currency}
                onChange={handleGeneralDetailsChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              >
                {currencyOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div></div>
            <div className='space-y-3'>
              <label
                htmlFor='timeZone'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Timezone
              </label>
              <select
                name='timeZone'
                value={formData.generalDetails.timeZone}
                onChange={handleGeneralDetailsChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              >
                {timeZones.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className='space-y-3'>
              <label
                htmlFor='language'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                Language
              </label>

              <select
                name='language'
                value={formData.generalDetails.language}
                onChange={handleGeneralDetailsChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              >
                <option value=''>Select Language</option>
                <option value='English'>English</option>
                <option value='Arabic'>Arabic</option>
                <option value='Mandarin Chinese'>Mandarin Chinese</option>
                <option value='French'>French</option>
                <option value='German'>German</option>
                <option value='Korean'>Korean</option>
                <option value='Turkish'>Turkish</option>
                <option value='Japanese'>Japanese</option>
                <option value='Spanish'>Spanish</option>
                <option value='Russian'>Russian</option>
                <option value='Italian'>Italian</option>
                <option value='Portuguese'>Portuguese</option>
                <option value='Vietnamese'>Vietnamese</option>
                <option value='Persian'>Persian</option>
                <option value='Hindi'>Hindi</option>
                <option value='Indonesian'>Indonesian</option>
                <option value='Thai'>Thai</option>
                <option value='Farsi'>Farsi</option>
                <option value='Polish'>Polish</option>
                {/* options for languguages */}
              </select>
            </div>
          </div>
          <div className='h-[0px] border border-gray-200 my-8 w-full'></div>
          <h3 className='text-neutral-400 text-xs font-semibold uppercase leading-relaxed tracking-wide my-8 mb-4'>
            REPORTING PERIOD
          </h3>
          <div className='grid grid-cols-3 gap-5'>
            <div className='space-y-3'>
              <label
                htmlFor='fromDate'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                From Date
              </label>
              <input
                type='date'
                name='fromDate'
                id='fromDate'
                value={formData.reportingPeriodInformation.fromDate}
                onChange={handleReportingPeriodChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              />
            </div>
            <div className='space-y-3'>
              <label
                htmlFor='toDate'
                className='block text-neutral-800 text-[13px] font-normal'
              >
                To Date
              </label>
              <input
                type='date'
                name='toDate'
                id='toDate'
                value={formData.reportingPeriodInformation.toDate}
                onChange={handleReportingPeriodChange}
                className='border border-gray-300 rounded-md w-full p-2 text-neutral-500 text-xs font-normal leading-tight'
              />
            </div>
            <div></div>
          </div>
          <div className='space-y-3 mt-4'>
            <label
              htmlFor='reportingFramework'
              className='block text-neutral-800 text-[13px] font-normal mb-3'
            >
              Reporting Framework
            </label>
            {reportFramework.map((framework) => (
              <label
                key={framework}
                className='text-md font-semibold text-gray-700 me-4'
              >
                <input
                  type='checkbox'
                  value={framework}
                  // checked={selectedFrameworks.includes(framework)}
                  checked={true}
                  onChange={handleFrameworkChange}
                  className='p-1 me-2 text-black text-opacity-90 text-xs font-normal leading-[18px] tracking-tight'
                />
                {framework}
              </label>
            ))}
          </div>
          <div className='w-full h-[0px] border border-gray-200 my-12'></div>
        </div>
      </div>
      {/* <BusinessActivities />
      <BusinessRelationships /> */}
    </div>
  );
};

export default Location;
