import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import DataTable from "components/Data-table/Datatable";
import { TaskArray, getData } from "components/data/scopeInfo";

const Index = () => {
  const [newdata ,setNewdata] = useState([]);
  const isMounted = useRef(true);
  const fetchMytaskDetails = async () => {
    // console.log("user id ", localStorage.getItem("user_id"));
    const stringWithQuotes = localStorage.getItem("accessToken");
    const stringWithoutQuotes = stringWithQuotes.replace(/"/g, "");
    const options = {
      headers: {
        Authorization: `Bearer ${stringWithoutQuotes}`,
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/sustainapp/user_all_task/`,
      options
    );
    console.log(response.data, "my list data");
    setNewdata(response.data);
  };
  useEffect(() => {
    if (isMounted.current) {
      fetchMytaskDetails();
      isMounted.current = false;
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  

  return (
    <div>
      <DataTable data={newdata} defaultItemsPerPage={10} />
    </div>
  );
};

export default Index;
