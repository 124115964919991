import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
const months = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const Energyheader = ({ activeMonth, setActiveMonth }) => {
  const [locations, setLocations] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const isMounted = useRef(true);
  const fetchloction = async () => {
    // console.log("user id ", localStorage.getItem("user_id"));
    // const stringWithQuotes = localStorage.getItem("authTokens");
    const stringWithQuotes = localStorage.getItem('accessToken')
    const stringWithoutQuotes = stringWithQuotes.replace(/"/g, "");
    const options = {
      headers: {
        Authorization:'Bearer ' + stringWithoutQuotes,
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/structure`,
      options
    )
    .then((response) => {
      console.log(response.data);
      setLocations(response.data);

    })
    .catch((error) => {
      console.error('Error fetching data: ', error);
    });
  };
  useEffect(() => {
    if (isMounted.current) {
      fetchloction();
      isMounted.current = false;
    }
    return () => {
      isMounted.current = false;
    };

  }, []);
  return (
    <>
      <div className="ml-2 mb-5">


        <div className="flex mb-5">
          <div className="w-64">
            <select className="rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-full">
              {/* Add location options here */}
              <option value="location1">Select location</option>
            </select>
          </div>
          <div className="ml-2 w-64">
            <select
              className="rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-full"

            >
              <option value="location1">Select year</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              {/* Repeat for other years as needed */}
            </select>
          </div>
          {/* <div className="ml-2 w-64">
            <select className="rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-full">

              <option value="location1">Select quarter</option>
            </select>
          </div> */}

        </div>
        <div className="flex justify-between mb-4">
          <div className="flex  bg-[#f7f7f7] py-1 rounded-lg">
            {months.map((month, index) => (
              <button
                key={index}
                className={`text-[12px] border-r mx-1 ${activeMonth === month ? 'bg-white shadow-md rounded-lg' :''}`}
                onClick={() => setActiveMonth(month)}
              >
                <p className={`text-center ${activeMonth === month ? 'custom-gradient-text' : 'text-[#A1A1A1]'} hover:bg-[#f7f7f7]  py-1 w-[60px] ${index === 0 ? 'rounded-l' : ''} ${index === months.length - 1 ? 'rounded-r' : ''}`}>{month}</p>
              </button>
            ))}
          </div>

        </div>
      </div>
    </>
  );
}

export default Energyheader;