import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Aside from 'components/collect/Aside';
import Emissions from 'components/collect/Emissions';
import { useProSidebar } from 'react-pro-sidebar';
import { setActiveNav } from 'state';
import { useLocation } from 'react-router-dom';
import Energyconsumption from 'pages/Energy/energy-consumption/energy-consumption';
import Energyconsumptionoutside from 'pages/Energy/energy-consumption-outside/energy-consumption-outside';
import Energyintensity from 'pages/Energy/energy-intensity/energy-intensity';
import Reductionenergyconsumption from 'pages/Energy/reduction-energy-consumption/reduction-energy-consumption';
import Energyproductsservices from 'pages/Energy/energy-products-services/energy-products-services';
import Significantwaste from './Waste/significant-waste/significant-waste';
import Managementwaste from './Waste/management-waste/management-waste';
// import Wastegenerated from './Waste/waste-generated/waste-generated';
// import Wastefromdisposal from './Waste/waste-diverted-from-disposal/waste-from-disposal';
const Environment = () => {
  const [activeTab, setActiveTab] = useState('Emissions');
  const { collapseSidebar, collapsed } = useProSidebar();
  const activeNav = useSelector((state) => state.global.activeNav);

  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const parts = path.split('/');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    dispatch(setActiveNav(tab));
  };

  useEffect(() => {
    console.log(parts, parts[parts.length - 1]);
    if (
      parts[parts.length - 1] === 'environment' &&
      (!activeNav || !['Emissions', 'Energy', 'Waste'].includes(activeNav))
    ) {
      setActiveTab('Emissions');
      dispatch(setActiveNav('Emissions'));
    }
    return () => {
      dispatch(setActiveNav(''));
    };
  }, []);

  return (
    <div className='flex overflow-x-hidden'>
      <Aside activeTab={activeTab} handleTabClick={handleTabClick} />
      <div
        className={`${
          collapsed ? 'min-w-[84%]' : 'min-w-[82%]'
        } transition-all me-0`}
      >
        {/* Emissions start  */}
        {activeTab === 'Emissions' && <Emissions />}
           {/* Energy start  */}
        {activeTab === 'Energy consumed inside the organization' && <Energyconsumption />}
        {activeTab === 'Energy consumption outside of the organization' && <Energyconsumptionoutside />}
        {activeTab === 'Energy Intensity' && <Energyintensity/>}
        {activeTab === 'Reduction of energy consumption' && <Reductionenergyconsumption/>}
        {activeTab === 'Reductions in energy requirements of products and services' && <Energyproductsservices/>}
          {/* waste start  */}
        {activeTab === 'Significant waste related impact' && <Significantwaste/>}
        {activeTab === 'Management of significant waste related impacts' && <Managementwaste/>}
        {/* {activeTab === 'Waste generated' && <Wastegenerated/>}
        {activeTab === 'Waste Diverted from disposal' && <Wastefromdisposal/>}
        {activeTab === 'Waste diverted to disposal' && <Energyproductsservices/>} */}
      </div>
    </div>
  );
};

export default Environment;
